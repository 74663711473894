import React from "react";
var Chart = require("react-google-charts").Chart;

const Table2 = ({ monthsdata }) => {
    
    return (
        <div className="sec_block">
    <div className="sec_blockhead">
      <h5>Month wise shipments</h5>
    </div>
    <div className="sec_table srchrslt_table">
    <div  className="all_filter">
<Chart
  width={'100%'}
  height={'100%'}
  chartType="AreaChart"
  loader={<div>Loading Chart</div>}
   data={monthsdata}
//   data={[
//     ['Year', 'Sales', 'Expenses'],
//     ['2013', 1000, 400],
//     ['2014', 1170, 460],
//     ['2015', 660, 1120],
//     ['2016', 1030, 540],
//   ]}
  options={{
    title: 'Month wise shipments',
    hAxis: { title: 'Month Year', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 },
    // For the legend to fit, we make the chart area smaller
    chartArea: { width: '80%', height: '50%' },
    // lineWidth: 25
  }}
  // For tests
  rootProps={{ 'data-testid': '1' }}
/>
</div>
</div>
</div>
 );
}
export default Table2