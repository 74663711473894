import React, { Component } from 'react';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons'

import Moment from 'moment';

export default class WorkPlace extends Component {

    constructor(props) {
      super(props);
      this.state = {WorkPlaceList: []
       
       
    };
     
    }
    
    componentDidMount() {
        this.Invoicelistfunction();
        
     
      }
  
    handleSubmit(event) {
     


      event.preventDefault();
      
     
    }
    
    async Invoicelistfunction()
    {
        const requestData={CustomerID:APIcustomerID()}
        const usaWorkPlaceList=callApi("POST",requestData,Constant.USDATAWORKPLACE);
        usaWorkPlaceList.then((responseData)=>{
        this.setState({WorkPlaceList:responseData.DataResponse.Data});
          
            });
        
         
    }
   
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-12">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>My WorkPlace</h5>
                </div>
               
                
                
                <div className="sec_table invoice_table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Date Time</th>
                                <th>Display Name</th>
                             
                                <th>Record</th>
                             
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        {(this.state.WorkPlaceList.length > 0) ? this.state.WorkPlaceList.map((item, index) => {
                                        
                                        var DownloadURL="/Usefull/UserData/usa/"+item.CustomerID+"/"+item.DownloadsID+".xlsx";
                                        if(item.DownloadsID<=28299)
                                        {
                                          DownloadURL="/Usefull/UserData/usa/"+item.CustomerID+"/"+item.FileName;
                                        }
                                          return (
                                    <tr>
                                        <td data-label="Date"> {Moment(item.DownloadDate).format("DD-MMM-yyyy hh:mm:ss a")}</td>
                                      
                                        <td data-label="DisplayName">{item.DisplayName}</td>
                                        <td data-label="RecordsDonloads">{item.RecordsDonloads}</td>
                                       
                                        <td data-label="Download">
                                      

                                            
                                            <a href={DownloadURL} target="_blank" > <FontAwesomeIcon icon={faDownload} /></a></td>
                                    </tr>
                        )}): <div></div>
                                    }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  