//import React from 'react';
//debugger;
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
const tablcountry = ({ countrylist,onChangevalueData }) => {
  return (
    
<div className="sec_block">
            <div className="sec_blockhead">
              <h5>Country List</h5>
            </div>
            <div className="sec_table srchrslt_table">
            <div  className="all_filter">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>Select </Th>
                    <Th>Country Name</Th>
                    <Th>Total Shipment</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    { (countrylist.length > 0) ? JSON.parse(countrylist).map( (item, index) => {
                    return (
                    <Tr key={ index }>
                    <Td><label><input type="checkbox" value={item.key}
            onChange={(data)=>onChangevalueData(item.key)} /></label></Td>
                    <Td>{item.key.split(',')[1]}</Td>
                    <Td>{item.doc_count}</Td>
                    </Tr>
                    )
                     }) : <Tr><Td colSpan="3">Loading...</Td></Tr> }
      </Tbody>
      </Table>
     </div>
     </div>
     </div>
    
  );
}
export default tablcountry