import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './style.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';






import Routes from "./Routes";

ReactDOM.render(<Routes  />, document.getElementById("root"));
// ReactDOM.render(
//   <Routes />,
//   // <React.StrictMode>
//   //   <App />
//   // </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
ReactGA.initialize("UA-71307501-1");
ReactGA.pageview(window.location.pathname);


