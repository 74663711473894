import React from 'react';
//debugger;
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
const buyerdataTable = ({ buyerlist,BuyerShowMoreButton,onChangevalueDataBuyer,onclickmorebuyer }) => {
  let MoreDiv=<div>No More Data</div>
  
  if(BuyerShowMoreButton>0)
  {
    
    MoreDiv=<button className="btn btn-primary" type="button" onClick={(data)=>onclickmorebuyer(JSON.parse(buyerlist).length)} >Load More</button>
  }
  return (
    
    <div className="sec_block">
            <div className="sec_blockhead">
              <h5>Buyer List</h5>
            </div>
            <div className="sec_table srchrslt_table">
            <div  className="all_filter">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>Select </Th>
                    <Th>Buyer Name</Th>
                    <Th>Total Shipment</Th>
                    </Tr>
                    </Thead>
                    <Tbody>

                    {JSON.parse(buyerlist).map( (element, index) => {
                      
                    return (
                    <Tr  >
                    <Td><label><input type="checkbox" value={element.key}
            onChange={(data)=>onChangevalueDataBuyer(element.key)} /></label></Td>
                    <Td>{element.key}</Td>
                    <Td>{element.doc_count}</Td>
                    </Tr>
                    
                    )
                     })} 
                     
                       {/* <Tr><Td><button type="button" onClick={(data)=>onclickmorebuyer(buyerlist.length)} >Lodmore</button>
                         </Td></Tr> */}
                         <Tr><Td colSpan="3">

{MoreDiv}
               
               </Td></Tr>
                    
      </Tbody>
      </Table>
     </div>
     </div>
     </div>
    
  );
}
export default buyerdataTable