import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Logo from '../../Usefull/images/logo.png'
const Login = () => 
{
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState();
  const history = useHistory();

  const onSubmit = (data, e) => {
    setMessage({data: "Login is in progress...",type: "alert alert-primary",});
    
    const requestData={username:data.username,password:data.password}
    const usaData=callApi("POST",requestData,Constant.USDATALOGIN);
    usaData.then((responseData)=>{
      console.log(responseData.DataResponse.error);
      setMessage({
        data: responseData.DataResponse.error || "Logged in successfully, redirecting to dahsboard...",
        type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
      });

      if(responseData.DataResponse.error===null)
      {
      


        localStorage.setItem("token", responseData.DataResponse.Data.token);

        const requestData={customerid:responseData.DataResponse.Data.token}
        const usaProfileDetails=callApi("POST",requestData,Constant.USDATACURRENTSUBS);
        usaProfileDetails.then((responseData)=>{

          let checksubs=responseData.DataResponse[0].Plans
          if(checksubs===null)
          {
            setTimeout(() => {
         
              history.push("/");
            }, 1000);
          }
          else if(checksubs==="NA")
          {
            setTimeout(() => {
         
              history.push("/");
            }, 1000);
          }
          else{
            setTimeout(() => {
         
              history.push("/app/dashboard");
            }, 1000);
          }

          
        });


       
      }
      !responseData.DataResponse.error && e.target.reset();
       //this.setState({isSubmitting: false});
      //this.setState({usadata:responseData.data})
    });
    
  };
  

  return (
    <div className="app">
    <Helmet>
       <title>Login Form Details - Seair Exim Solutions</title>
       <meta name="description" content="Login Now with the world’s leading import export data portal of Seair Exim Solution and get details of buyers and suppliers all at one place." />
    </Helmet>
    <section className="login_page">
    <div className="container-fluid">
    <div className="row">
  

      <div className="col-lg-4 col-sm-12 offset-lg-4 offset-md-0">
        <div className="login_height">
         <div className="login_sec">
           <Link to="/" className="logo">
         
           <img src={Logo} alt="Seair Exim Solution" />
         </Link>
            <h2 className="common_heading">Welcome Back</h2>
         <p className="common_tagline">
            Please Sign in to your Account.
             </p>
         <div className="login_box">

         

        {message && (
          <div
            role="alert" className={message.type}
          >
            <p className="mb-0">
            
            {message.data}  
            <span
              aria-hidden="true"
              className="alert-link float-right"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
            </p>
            
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="inputForEmail">Email Address</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('username', { required:{value:true,message:"Please enter your email address"}
              ,pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please enter valid email id.',
              },
            maxLength:{value:150,message:"Please enter max 150 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter email address" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.username && (
                <span className=" mandatory">
                  {errors.username.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
            
            <label htmlFor="inputForPassword">Password </label>
            <span className="mandatory">*</span>  
              <input type="password" {...register('password', { required:{value:true, message: "Please enter password"},minLength:{value:5,message:"Please enter min 5 characters"},maxLength:{value:25,message:"Please enter max 10 characters"}  })} className="form-control form_control" placeholder="Enter password" />
              
              {errors.password && (
                <span className= "mandatory">
                  {errors.password.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Login
              </button>

              </div>
            </div>
          </form>
       
      </div>
      <p className="form_botm">New User ? 
      <Link to="/account/register">Signup Now</Link>
      </p>
      <p className="form_botm">  <Link to="/account/forget">Forgot Password</Link></p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    </div>
  
  );
};

export default Login;