import React, { useState,useEffect } from 'react';

import Header from '../Shared/Header';

import Navigation from '../Shared/Navigation';
import { useForm } from "react-hook-form";
import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';

//export default class Profile extends Component {
    const Profile = () => {
   
    const { register, handleSubmit, formState: { errors },setValue } = useForm();
    const [message, setMessage] = useState();
    
    
    useEffect(() => {
        // if (!isAddMode) {
            // get user and set form fields
            const requestData={customerid:APIcustomerID()}
            const usaProfileDetails=callApi("POST",requestData,Constant.USDATAPROFILEDETAILS);
            usaProfileDetails.then((responseData)=>{

            responseData.DataResponse.forEach(team => 
              {
                
                setValue('CustomerID', team.CustomerID)
                setValue('FirstName', team.FirstName)
                setValue('LastName', team.LastName)
                setValue('Designation', team.Designation)
                setValue('EmailID', team.EmailID)
                setValue('Companyname', team.Companyname)
                setValue('Address', team.Address)
                setValue('Country', team.Country)
                setValue('State', team.State)
                setValue('City', team.City)
                setValue('ZipCode', team.ZipCode)
                setValue('MobileNo', team.MobileNo)
                setValue('WebUrl', team.WebUrl)
                setValue('StateCode', team.StateCode)
                
                setValue('GSTIN', team.GSTIN)

                  
            });
        
                });
          
    }, []);
    // componentDidMount() 
    // {
    //     this.ProfileDetailsfunction();
     
    // }
  
    const onSubmit = (data, e) => 
    {
        setMessage({data: "Your Profile Update is in progress...",type: "alert alert-warning",});
        const requestData=data;//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.USDATAPROFILEUPDATE);
        usaData.then((responseData)=>{
          console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Profile has been updated successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          
    
        });


       
    };
  
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-6">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>My Profile</h5>
                </div>
                <div className="pro_form sec_table invoice_table col-md-12">
                {message && (
          <div
            role="alert" className={message.type}
          >
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
                       
                    <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="firstname">First Name</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('FirstName', { required:{value:true,message:"Please enter your First name"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter First Name" disabled />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.FirstName && (
                <span className=" mandatory">
                  {errors.FirstName.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">Last Name</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('LastName', { required:{value:true,message:"Please enter your Last name"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter Last Name" disabled />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.LastName && (
                <span className=" mandatory">
                  {errors.LastName.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <span htmlFor="lastname">Designation</span>
              <input type="text" { ...register('Designation')
              } className="form-control form_control" placeholder="Enter Last Name" />
              
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">Companyname</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Companyname', { required:{value:true,message:"Please enter your Companyname"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter Companyname" disabled />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Companyname && (
                <span className=" mandatory">
                  {errors.Companyname.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">Address</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Address', { required:{value:true,message:"Please enter your Address"}
              
            ,maxLength:{value:100,message:"Please enter max 100 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter Address" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Address && (
                <span className=" mandatory">
                  {errors.Address.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">Country</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Country', { required:{value:true,message:"Please enter your Country"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter Country" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Country && (
                <span className=" mandatory">
                  {errors.Country.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">State</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('State', { required:{value:true,message:"Please enter your State"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter State" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.State && (
                <span className=" mandatory">
                  {errors.State.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">City</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('City', { required:{value:true,message:"Please enter your City"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter City" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.City && (
                <span className=" mandatory">
                  {errors.City.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="lastname">ZipCode</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('ZipCode', { required:{value:true,message:"Please enter your ZipCode"}
              
            ,maxLength:{value:10,message:"Please enter max 10 characters"},
            minLength:{value:2,message:"Please enter min 2 characters"} })
              } className="form-control form_control" placeholder="Enter ZipCode" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.ZipCode && (
                <span className=" mandatory">
                  {errors.ZipCode.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="inputForEmail">Email address</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('EmailID', { required:{value:true,message:"Please enter your email address"}
              ,pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please enter valid email id.',
              },
            maxLength:{value:150,message:"Please enter max 150 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter email address" disabled />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.EmailID && (
                <span className=" mandatory">
                  {errors.EmailID.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
            <label htmlFor="mobilenumber">Mobile Number</label>
              <span className="mandatory">*</span>
              
            
            
              <input type="text" { ...register('MobileNo', { required:{value:true,message:"Please enter your Mobile Number"}
              ,pattern: {
                value: /^[0-9\b]+$/,
                message: 'Please enter valid mobile Number.',
              },
            maxLength:{value:15,message:"Please enter max 15 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter Mobile Number" disabled />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.MobileNo && (
                <span className=" mandatory">
                  {errors.MobileNo.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <span htmlFor="WebUrl">WebUrl</span>
             
              <input type="text" { ...register('WebUrl')
              } className="form-control form_control" placeholder="Enter WebUrl" />
              
              
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <span htmlFor="lastname">StateCode</span>
              
              <input type="text" { ...register('StateCode')
              } className="form-control form_control" placeholder="Enter StateCode" />
              
              
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <span htmlFor="lastname">GSTIN</span>
              
              <input type="text" { ...register('GSTIN')
              } className="form-control form_control" placeholder="Enter GSTIN" />
              
            </div>
           
            <div className="form-group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Update
              </button>

              </div>
            </div>
            </div>
          </form>
       
                       
                            
                      
                  
                </div>
            </div>
        </div>
    </div>
            </div>
        </section>
        </div>
        );
    
    
  };
  export default Profile;