import React, { useState ,useEffect} from "react";
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { useForm } from "react-hook-form";
import 'react-phone-number-input/style.css'
import { callApi,APIcustomerID } from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';

const CreateTicket = () => 
{
  const { register, handleSubmit } = useForm();
  const [message, setMessage] = useState();

  
  
  const onSubmit = (data, e) => {
    setMessage({data: "Your ticket submission is in progress...",type: "alert alert-warning",});
    
        const requestData={TicketNo:0,CustomerID:APIcustomerID()
        ,SubjectID:data.SubjectID
        ,PriorityID:data.PriorityID
        ,TicketStatusID:"1"//data.Year
        ,Message:data.Message
        ,AssignedTo:0
        ,Sysdate:new Date()
        ,LastUpdated:new Date()
        ,IsActive:true//data.Month
      
        ,DMLFlag:'I'

    };//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.USDATACREATETICKETS);
        usaData.then((responseData)=>{
         
          setMessage({
            data: responseData.DataResponse.error || "Your ticket has been submitted successfully!",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
    
        });
        
  };
  

  return (
    <div className="app">
    <Header />
    <Navigation/> 
    <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-6">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>New Ticket</h5>
                </div>
                <div className="pro_form sec_table invoice_table col-md-12">
        {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="FirstName">Subject</label>
              <span className="mandatory">*</span>
              <select className="form-control form_control" { ...register('SubjectID')
              }>
                    <option value="1">How to buy new subscription plan?</option>
              <option value="2">How to Search Product, Buyer & Supplier?</option>
            <option value="3">My plan is not activated?</option>
              
              </select>
              
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="FirstName">Priority</label>
              <span className="mandatory">*</span>
              <select className="form-control form_control" { ...register('PriorityID')
              }>
              <option value="1">Low</option>
              <option value="2">Normal</option>
            <option value="3">High</option>
              </select>
              
            </div>
            
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="Companyname">Message</label>
              <span className="mandatory">*</span>
              <textarea type="text"  required="required" { ...register('Message')
              } className="form-control form_control" placeholder="Enter Message" ></textarea>
              
            </div>
           
            


            
            <div className="form-group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Submit
              </button>

              </div>
            </div>
            </div>
          </form>
       
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
      </div>
    
  

  );
};

export default CreateTicket;