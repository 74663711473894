import React, { Component } from 'react';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import * as Constant from '../../Utilities/Constant';
import Moment from 'moment';

export default class ShipmentsDetails extends Component {

    constructor(props) {
      super(props);
      
        this.state = {
            
            Recordsid:this.props.location.state.Recordsid,
            DataList:[]

    };
     
    }
    
componentDidMount() 
    {
        console.log(this.state.Recordsid)
        

let icustomerid=APIcustomerID();
const requestDataStatus={customerid:icustomerid}
const USAPlanStatus=callApi("POST",requestDataStatus,Constant.USDATASUBSSTATUS);
USAPlanStatus.then((responseData)=>{

if(responseData.DataResponse[0].Total>0)
{
  
  let TotlDonwloadrecords=1;//this.state.dataDownloadsSingle.length;
  if(TotlDonwloadrecords>0)
  {
  const DownloadStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKDOWNLOADS);
  DownloadStatus.then((dresponseData)=>
  {
  
    var Totalremaining=dresponseData.DataResponse[0].Totalremaining;
    if(Totalremaining>TotlDonwloadrecords)
    {
      //console.log(dresponseData.DataResponse[0].Totalremaining);
      const DownloadTodayStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKTODAYDOWNLOADS);
      DownloadTodayStatus.then((DTodayresponseData)=>{

        Totalremaining= DTodayresponseData.DataResponse[0].Totalremaining;
        if(Totalremaining>TotlDonwloadrecords)
        {
              
                
                    var displayname="From Details View";
                   
                  const requestData={CustomerID:icustomerid,SourceIP:'',FileName:displayname.trim()+".xlsx",DisplayName:displayname,TotalRecords:TotlDonwloadrecords}
                  const usaData=callApi("POST",requestData,Constant.USDATACREATEWORKPLACE);
                  usaData.then((responseData)=>{
                    var dataDownloadsSingle=[];
                    dataDownloadsSingle.push(this.state.Recordsid)
                    let FileName=responseData.DataResponse.Data[0].DownloadsID+'.xlsx'
                    //For Saving Data excel data
                    const requestDataExcel={CustomerID:icustomerid,SourceIP:'',FileName:FileName,datarecords:JSON.stringify(dataDownloadsSingle)}
                    const usaDataExcel=callApi("POST",requestDataExcel,Constant.USDATADOWNLOADSINGLE);
                    usaDataExcel.then((responseDataExcel)=>{

                        this.ShipmentsDetails();
                      
                    });
                  
                    
                  });

               
        }
        else
        {
          alert("Your daily data limit is exceeded.")
          
        }
       
      });
    }
    else
    {
      alert("Insufficient Data Access")
      
    }
    
  
  });
}
else
{
  alert("Please Select Records")
}

}
else
{
  alert("No Subscription please buy the plan.")
}


});
     
}
  
    
    
    async ShipmentsDetails()
    {
        var dataDownloadsSingle=[];
        dataDownloadsSingle.push(this.state.Recordsid)
       
        const requestData={datarecords:JSON.stringify(dataDownloadsSingle)}
        console.log(requestData)
        const usaInvoiceDetails=callApi("POST",requestData,Constant.USDATADOWNLOADVVIEWSINGLE);
        usaInvoiceDetails.then((responseData)=>{
            console.log(responseData.dataCount[0]._source);
        this.setState({DataList:responseData.dataCount});
          
            });
        
         
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">

            <div class="sec_block">
            <div class="sec_blockhead">
                <h5>Shipment Detail</h5>
            </div>
            <div class="table_tab">
            { (this.state.DataList.length > 0) ? this.state.DataList.map( (cut, index) => {
           return (
                    <table class="table ">
                        <thead>
                            <tr>
                                <th class="w-25">Arrival Date</th>
                                <td>
                                { Moment(cut._source.arrivaldate).format("DD-MMM-yyyy") }
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Manufacturer / Shipper
                                </th>
                                <td>
                                {cut._source.shippername}<br />
                                    {cut._source.shipperaddress}

                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Consignee
                                </th>
                                <td>
                                {cut._source.consigneename}<br />
                                    {cut._source.consigneeaddress}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Notify Party

                                </th>
                                <td>
                                {cut._source.notifypartyname}<br />
                                    {cut._source.notifypartyaddress}


                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Weight (KG)

                                </th>

                                <td>
                                    {cut._source.weightinkg}
                                </td>
                            </tr>



                            <tr>
                                <th>
                                    Weight (LB)

                                </th>

                                <td>
                                    {cut._source.weight}
                                </td>
                            </tr>









                            <tr>
                                <th>
                                    Quantity
                                </th>

                                <td>
                                    {cut._source.quantity}
                                </td>
                            </tr>





                            <tr>
                                <th>
                                    Measure (CM)
                                </th>

                                <td>
                                    {cut._source.measureincm}
                                </td>
                            </tr>



                            <tr>
                                <th>
                                    Measure
                                </th>

                                <td>
                                    {cut._source.measure}
                                </td>
                            </tr>



                            <tr>
                                <th>
                                    Measure Unit
                                </th>

                                <td>
                                    {cut._source.measureunit}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Country of Origin
                                </th>

                                <td>
                                {cut._source.country}
                                
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Details
                                </th>

                                <td>
                                <div dangerouslySetInnerHTML={{ __html: cut._source["productdesc"] }} />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Place of Receipt

                                </th>

                                <td>
                                    {cut._source.placeofreceipt}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Loading Port
                                </th>

                                <td>
                                    {cut._source.loadingport}
                                </td>
                            </tr>





                            <tr>
                                <th>
                                    Unloading Port
                                </th>

                                <td>
                                    {cut._source.unloadingport}
                                </td>
                            </tr>
                           

                            <tr>
                                <th>
                                    Container Number
                                </th>
                                <td>{cut._source.containerid}</td>
                            </tr>
                            <tr>
                                <th>
                                    Marks and Numbers

                                </th>
                                <td>
                                {cut._source.marksnumbers}

                                </td>
                            </tr>



                            <tr>
                                <th>
                                    Carrier Name

                                </th>
                                <td>
                                {cut._source.carriersasccode}

                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Vessel Name

                                </th>
                                <td>
                                {cut._source.vesselname}

                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Voyage Numbe

                                </th>
                                <td>
                                {cut._source.voyage}

                                </td>
                            </tr>


                            <tr>
                                <th>
                                    Master Bill of Lading Number
                                </th>
                                <td>{cut._source.masterbilloflading}</td>
                            </tr>

                            <tr>
                                <th>HTS Codes</th>
                                <td>{cut._source.hscode}</td>
                            </tr>
                        </thead>
                    </table>
                 )
                }) : "" }
            </div>
        </div>


    
            
    </div>
    </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  