// import 'babel-polyfill'
import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Dashboard from "./components/Dashboard/Dashboard";
import Home from './components/Home/home';
//import Logout from '../Login/Logout';
import Search from './components/Data/Data';
import Subscription from './components/Subscription/Subscription';
import Invoices from './components/Invoices/Invoices';
import InvoicePrint from './components/Invoices/InvoicePrint';
import WorkPlace from './components/WorkPlace/WorkPlace';
import Profile from './components/Profile/Profile';
import ChangePassword from './components/ChangePassword/ChangePassword';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Createtoolbox from './components/Dashboard/Createtoolbox';
import Updatetoolbox from './components/Dashboard/Updatetoolbox';
import Tickets from './components/Tickets/Tickets';
import CreateTicket from './components/Tickets/CreateTicket';
import TicketDetails from './components/Tickets/TicketDetails';
import ShipmentsDetails from './components/Data/ShipmentsDetails';
import NotFound from "./components/NotFound/NotFound";



const authGuard = (Component) => () => 
{
  return localStorage.getItem("token") ? (
    <Component />
  ) : (
    <Redirect to="/account/login" />
  );
};
const Routes = (props) => (
  
  <Router {...props}>
    <Switch>
      
      <Route path="/account/login">
        <Login />
      </Route>
      <Route path="/account/forget">
        <ForgotPassword />
      </Route>
      <Route path="/account/register">
        <Register />
      </Route>
      <Route path="/app/dashboard" render={authGuard(Dashboard)} >
       
      </Route>
      <Route path="/app/shipmentsdetails" render={(props)=> (
          <ShipmentsDetails {...props} propDummy={50} />
        )} />
      {/* <Route path="/app/search" 
      render={(props) => (
        <Search {...props} isAuthed={true} />
      )}
      >
      
      </Route> */}
{/* <Route path="/app/dashboard" render={(props)=> (
          <Dashboard {...props} propDummy={50} />
        )} /> */}

        <Route path="/app/search" render={(props)=> (
          <Search {...props} propWhatever={100} />
          )} /> 
      {/* <Route path="/app/search" render={authGuard(Search)}>
       
      </Route> */}
      <Route path="/app/profile"  render={authGuard(Profile)} >
      
      </Route>
      <Route path="/app/changepassword"  render={authGuard(ChangePassword)} >
      
      </Route>
      <Route path="/app/createtoolbox"  render={authGuard(Createtoolbox)} >
      
      </Route>
      <Route path="/app/updatetoolbox" render={(props)=> (
          <Updatetoolbox {...props} propDummy={50} />
        )} />
      
     
      <Route path="/app/subscription"  render={authGuard(Subscription)} >
      
      </Route>
      <Route path="/app/invoices"  render={authGuard(Invoices)}>
     
      </Route>
      <Route path="/app/invoiceprint" render={(props)=> (
          <InvoicePrint {...props} propDummy={50} />
        )} />
       


      {/* <Route path="/app/invoiceprint/:id" >
     <InvoicePrint />
      </Route> */}
      
      <Route path="/app/workplace"  render={authGuard(WorkPlace)}>
     
      </Route>
      <Route path="/app/ticket"  render={authGuard(Tickets)}>
     
      </Route>
      <Route path="/app/ticketcreate"  render={authGuard(CreateTicket)}>
     
      </Route>
      <Route path="/app/ticketdetail" render={(props)=> (
          <TicketDetails {...props} propDummy={50} />
        )} />
      {/* <Route path="/app/ticketdetail"  render={authGuard(TicketDetails)}>
     
      </Route> */}
      <Route exact path="/">
      <Home />
      </Route>
      
      <Route component={NotFound} ></Route>
    </Switch>
    
  </Router>

);
export default Routes;