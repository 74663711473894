import React from "react";
import {Link
} from "react-router-dom";


import Logo from '../../Usefull/images/logo.png'
import Dash from '../../Usefull/images/dash.svg'
import Profileimg from '../../Usefull/images/profile.svg'
import work_place  from '../../Usefull/images/work_place.svg'
import invoice  from '../../Usefull/images/invoice.svg'
import subscription  from '../../Usefull/images/subscription.svg'
import offline_data  from '../../Usefull/images/offline_data.svg'
import Logout from '../Logout/Logout';
import ChangePassword  from '../../Usefull/images/changepassword.svg'
import website  from '../../Usefull/images/website.svg'

//import logout  from '../../Usefull/images/logout.svg'
//import side from '../../Usefull/images/side.jpg'

// import { AuthContext } from "../../App";
// const { dispatch } = React.useContext(AuthContext);
// const [data, setData] = React.useState(initialState);
// const initialState = {
//   email: "",
//   password: "",
//   isSubmitting: false,
//   errorMessage: null
// };


export default function Navigation() {
 
    //return dispatch => {
      // dispatch(requestLogout())
      // dispatch(receiveLogout())
     // localStorage.removeItem('user')
     // localStorage.removeItem('token')
      
    //  dispatch({ type: 'CLEAR_USER_RECORD' })
  //  }
  //}
    return (
      <aside className="side_nav" >
     
        
          <Link to="/app/dashboard"  className="brand_logo d-md-none d-block">
        
            <img src={Logo} alt="logo"></img>
         </Link>
        <ul>
            <li id="Layout_Dashboard"><Link to="/app/dashboard"><img src={Dash} alt="Dashboard"></img> <span>Dashboard</span></Link></li>
            {/* <li id="Layout_Dashboard"><Link to="/app/search"><img src={Dash} alt="Search"></img> <span>Search</span></Link></li> */}
            {/* <li id="Layout_Profile"><Link to="/app/graph"><img src={Profileimg} alt="Graph"></img> <span>Graph</span></Link></li> */}
            <li id="Layout_Profile"><Link to="/app/profile"><img src={Profileimg} alt="Profile"></img> <span>Profile</span></Link></li>
            <li id="Layout_Profile"><Link to="/app/changepassword"><img src={ChangePassword} alt="Change Password"></img> <span>Change Password</span></Link></li>
            <li id="Layout_WorkPlace"><Link to="/app/workplace"><img src={work_place} alt="Work Place"></img> <span>Work Place</span></Link></li>
            <li id="Layout_Invoice"><Link to="/app/invoices"><img src={invoice} alt="Invoice"></img> <span>Invoice</span></Link></li>
            <li id="Layout_Subscription"><Link to="/"><img src={subscription} alt="Subscription"></img> <span>Subscription</span></Link></li>
            <li id="Layout_OfflineData"><a href="https://www.seair.co.in/global-trade-data.aspx" target="_blank"><img src={offline_data} alt="Offline Data"></img> <span>Offline Data</span></a></li>
            <li id="Layout_OfflineData"><a href="https://www.seair.co.in/" target="_blank"><img src={website} alt="Visit Website"></img> <span>Visit Website</span></a></li>
           <li> <Logout /></li>
            {/* <li><Link to="/app/logout" ><span>Logout</span></Link></li> */}
        </ul>
      
        </aside>
    
    );
  }
  