import React from 'react';
import Moment from 'moment';
//debugger;
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
const tblmonths = ({ monthsdata ,onChangevalueData}) => {
  return (
      
<div className="sec_block">
            <div className="sec_blockhead">
              <h5>Months List</h5>
            </div>
            <div className="sec_table srchrslt_table">
            <div  className="all_filter">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>Select </Th>
                    <Th>Month Year</Th>
                    <Th>Total Shipment</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    { (monthsdata.length > 0) ? JSON.parse(monthsdata).map( (item, index) => {
                    return (
                    <Tr key={ index }>
                    <Td><label><input type="checkbox" value={item.key}
            onChange={(data)=>onChangevalueData(Moment(item.key_as_string).format("DD-MM-yyyy"))} /></label></Td>
                    <Td> { Moment(item.key_as_string).format("MMM-yyyy") }</Td>
                    <Td>{item.doc_count}</Td>
                    </Tr>
                    )
                     }) : <Tr><Td colSpan="3">Loading...</Td></Tr> }
                </Tbody>
              </Table>
           </div>
         </div>
</div>
   
  );
}
export default tblmonths