import React, {Component} from 'react';
import {Link
} from "react-router-dom";
// import LoadingBar from 'react-redux-loading-bar'

//import * as images  from '../../Usefull/images';
import Logo from '../../Usefull/images/logo.png'
import Logosvg from '../../Usefull/images/logo.svg'

import Userimg from '../../Usefull/images/user.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown,faBars,faTag } from '@fortawesome/free-solid-svg-icons'
import Logout from '../Logout/Logout';
import Currentsubscriptions from '../Profile/Currentsubscriptions.js'
import Navigation from '../Shared/Navigation'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
// import useGaTracker from '../../useGaTracker';

export default class Header extends Component {
    constructor(props) 
    {
          
      super(props);
      this.state = {isToggleOn: false};
      this.state = {isMobileMenu: false};
      this.state={FirstName:'',LastName:''}
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeMenu = this.handleChangeMenu.bind(this);
     
    }
    handleChange() {
        this.setState(prevState => ({
            isToggleOn: !prevState.isToggleOn
          }));
        //   console.log(this.state.isToggleOn)
      }
      handleChangeMenu() {
        this.setState(prevState => ({
            isMobileMenu: !prevState.isMobileMenu
          }));
          console.log(this.state.isMobileMenu)
      }
      componentDidMount() {
        
           if(APIcustomerID()!=null)
           {
               this.setState({isautho:true})
               const requestData={customerid:APIcustomerID()}
               const usaProfileDetails=callApi("POST",requestData,Constant.USDATAPROFILEDETAILS);
               usaProfileDetails.then((responseData)=>{
   
              
               responseData.DataResponse.forEach(team => 
                 {
                   
                   
                   this.setState({FirstName:team.FirstName,
                    LastName:team.LastName,
                    Password:team.Password
                   
                });
                  
   
                     
               });
           
                   });
           }
        
        //    useGaTracker();
           
     //dispatch(hideLoading())
         }
    render()
    
    {
        // <div>
        // <LoadingBar showFastActions  className="loading" /></div>
      return  localStorage.getItem("token") ? (
        <div>
            <header className="dashboard">
                
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-3 d-md-block d-none">
                    <Link to="/app/dashboard"  className="brand_logo">
        
                    <img src={Logo} alt=""></img>
     </Link>
                        
                    </div>
                    <div className="col-2 d-md-none d-block">
                        <button className="mob_navtabdash" onClick={this.handleChangeMenu} >
                        
                           <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>

                        </button>
                    </div>
                    <div className="col-md-6 col-8 dash_top_nav">
                    
                          <Currentsubscriptions></Currentsubscriptions>
                        
                          </div>
                   
                    <div className="col-md-3 col-2">
                        <div className="user_tab">
                        <button  onClick={this.handleChange}  className="img">
                            
                                <img src={Userimg} alt="profile images"></img>
                                <FontAwesomeIcon icon={faArrowDown} className="downicon" />
                                
                               
                        </button>
                      
                       
                            {/* <button className="mob_navtabdash">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button> */}
                            { (this.state.isToggleOn > 0) ? 
                 (
                        <div className="user_tabshow">
                        <div className="user_nameshow">
                        <Link  to="/app/profile" className="img"  >
                                <img src={Userimg} alt=""></img>
                          </Link>
                            <div className="user_namedes">
                                <h4>{this.state.FirstName} {this.state.LastName}</h4>
                                <p>Profile Short Desciption</p>
                            </div>
                            <Logout />
                            {/* <a className="user_namelog" href="/globaluser/LogOut">Logout</a> */}
                        </div>
                        <ul>
                            <li>
                            <Link  to="/app/profile"  >
                                Profile
                                </Link>
                            </li>
                            <li>
                            <Link  to="/app/changepassword"  >
                            Change Password
                                </Link>
                                
                            </li>
                        </ul>
                        <div className="user_chatbtn">
                            <ul>
                               
                                <li>
                               
                                <Link  to="/app/ticket"  ><FontAwesomeIcon icon={faTag}></FontAwesomeIcon>
                                Support Ticket
                                </Link>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    )
                     : <div></div> }
        
        

                        </div>
                    </div>
                </div>
            </div>
        </header>

        { (this.state.isMobileMenu > 0) ? 
                 (
                     <div className="side_navmobile">

<Navigation /> 
                     </div>
                 ) : <div></div> }
        </div>  
          ) : (
            <header >
             
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-sm-6 col-7">
                    <Link  to="/"  className="main_logo">
                        <img src={Logosvg} alt="" className="img-fluid"></img>
                    </Link>
       
                    </div>                  
                    <div className="col-lg-9 col-sm-6 col-5">
                    <ul>
                    {/* <li><Link to="/">Home</Link></li> */}
                        <li><Link to="/account/login">Login</Link></li>
                        <li><Link to="/account/register">Register</Link></li>

                    </ul>
                    </div>
                </div>
            </div>
        </header>
            
          )
       
    }
}