import React, { Link } from 'react';
import Moment from 'moment';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'

//for date formate npm install --save moment
//npm install react-super-responsive-table --save
//npm install --save react-pagination-table

const usaTable = ({ usadata,onChangevalueDataChekbox,singleview }) => {
  return (
    <Table className="table table-bordered">
      <Thead>
        <Tr>
         <Th></Th>
          <Th>Arrival Date</Th>
          <Th>Product Details</Th>
          <Th>Weight</Th>
          
          <Th>Qty Unit</Th>
          <Th>Buyer</Th>
          <Th>Supplier</Th>
        <Th>More</Th>
          
          
        
        </Tr>
      </Thead>
      <Tbody>
        { (usadata.length > 0) ? usadata.map( (cut, index) => {
           return (
            <Tr key={ index }>
            <Td>  <label >
          <input
            type="checkbox"
            value={cut._source.reocrdid}
            onChange={(data)=>onChangevalueDataChekbox(cut._source.reocrdid)}
           
          />
        
        </label>
              </Td>
              <Td>{ Moment(cut._source.arrivaldate).format("DD-MMM-yyyy") }</Td>
              <Td>
              <div dangerouslySetInnerHTML={{ __html: cut._source["productdesc"] }} />
              
                </Td>
              <Td>{ cut._source.weight }</Td>
              <Td>{ cut._source["quantityunit"] }</Td>
            
              
              <Td>{ cut._source["consigneename"] }</Td>
              <Td>{ cut._source["shippername"] }</Td>
              <Td>
             <button type="button" className="btndatareadmore" onClick={(data)=>singleview(cut._source.reocrdid)}><FontAwesomeIcon  icon={faBookOpen} ></FontAwesomeIcon></button>
                </Td>
              
              
          
            </Tr>
          )
         }) : <Tr><Td colSpan="5">Loading...</Td></Tr> }
      </Tbody>
    </Table>
    
  );
}

export default usaTable