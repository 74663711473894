import React, { Component } from 'react';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
// import {useParams, useLocation} from "react-router-dom";

import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes,faCheck } from '@fortawesome/free-solid-svg-icons'
import CurrencyFormat from 'react-currency-format';

import * as Constant from '../../Utilities/Constant';

import Moment from 'moment';

export default class Invoices extends Component {

    constructor(props) {
      super(props);
      
        this.state = {
            InvoiceDetails: [],
            CustomerDetails: [],
            customersubid:this.props.location.state.id
//            this.props.location.state.state.id
           // fromIdeas: props.params || 'unknown'
          
    };
     
    }
    
    componentDidMount() {
        //let location = useLocation;
       // const { match: { params } } = this.props;

        //console.log(location);
        // let { id } = useParams;
        // console.log(useParams);
        this.Invoicelistfunction();
     
      }
  
    handleSubmit(event) {
     


      event.preventDefault();
      
     
    }
    
    async Invoicelistfunction()
    {
        const requestData={customerid:APIcustomerID(),customersubid:this.state.customersubid}
        const usaInvoiceDetails=callApi("POST",requestData,Constant.USDATAINVOICEDETAILS);
        usaInvoiceDetails.then((responseData)=>{
            console.log(responseData);
        this.setState({InvoiceDetails:responseData.DataResponse.subsdetails,CustomerDetails:responseData.DataResponse.Customer});
          console.log(this.state.CustomerDetails)
            });
        
         
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">

            {(this.state.InvoiceDetails.length > 0) ? this.state.InvoiceDetails.map((item, index) => {
                let ispaid;
                if (item.IsPaid === true) 
                {
                    ispaid = <span className="paid">Paid <FontAwesomeIcon icon={faCheck} /></span>  ;
                } 
                else 
                {
                    ispaid = <span className="unpaid">UnPaid <FontAwesomeIcon icon={faTimes} /></span>;
                }
                
                let planname
                if (item.SubscriptionID === 5)
                {
                    planname = "Corporate";
                }
                if (item.SubscriptionID === 2)
                {
                    planname = "Standard";
                }
                if (item.SubscriptionID === 3)
                {
                    planname = "Executive";
                }
                if (item.SubscriptionID === 4)
                {
                    planname = "Business";
                }
                var Amoutusd;
                if(item.CustomerSubID >107579)
                {
                    if (item.SubscriptionID === 5)
                    {
                        Amoutusd = 400;
                    }
                    else if (item.SubscriptionID === 2)
                    {
                        Amoutusd = 80;
                    }
                    else if (item.SubscriptionID === 3)
                    {
                        Amoutusd = 150;
                    }
                    else if (item.SubscriptionID === 4)
                    {
                        Amoutusd = 350;
                    }
                    else{
                        Amoutusd = 0;
                    }

                }
                else
                {
                    if (item.SubscriptionID === 5)
                    {
                        Amoutusd = 350;
                    }
                    else if (item.SubscriptionID === 2)
                    {
                        Amoutusd = 50;
                    }
                    else if (item.SubscriptionID === 3)
                    {
                        Amoutusd = 80;
                    }
                    else if (item.SubscriptionID === 4)
                    {
                        Amoutusd = 150;
                    }
                    else{
                        Amoutusd = 0;
                    }
                }
                var totalAmountUSD=Amoutusd;
                var lblnetamountUSD=Amoutusd

                let lblcostINR=item.Cost;

                var AmoutINR;
                if(item.CostType==="USD")
                {
                    if (item.SubscriptionID === 5)
                    {
                        AmoutINR = 30000;
                    }
                    else if (item.SubscriptionID === 2)
                    {
                        AmoutINR = 5000;
                    }
                    else if (item.SubscriptionID === 3)
                    {
                        AmoutINR = 10000;
                    }
                    else if (item.SubscriptionID === 4)
                    {
                        AmoutINR = 25000;
                    }
                    else{
                        AmoutINR = 0;
                    }
                    lblcostINR=AmoutINR;
                }
                var stax = 18;
                var samount=(lblcostINR * stax) / 100;
                let lblservicestax=samount;
                var TotalAmount=(samount + lblcostINR);
                var lblnetamount=TotalAmount;
                //var lblcostINRp=lblcostINR;
                //var totalAmountINR=TotalAmount

            return (

        <div className="sec_block">
            <div className="sec_blockhead">
                <div className="row w-100 align-items-center">
                    <div className="col-sm-4 d-flex align-items-center">
                        <h5>My Invoice</h5>
                       
                    </div>

                </div>


            </div>
            <div className="subsrecord">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                        <h6>Invoice No. <span>{item.CustomerSubID}</span></h6>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-3 mt-md-0">
                        <h6>Date <span>{Moment(item.StartDate).format("DD-MMMM-yyyy")}</span></h6>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                        <h6>GST. <span>07ACDFS1650F1ZD</span></h6>
                    </div>
                    <div className="col-lg-3 col-md-6 mt-3 mt-lg-0">
                        <h6>
                            Status
                            {ispaid}
                        </h6>
                    </div>
                </div>
            </div>
            <div className="row pay_dets">
                <div className="col-lg-6 col-md-5">
                    <div className="payment_block">
                        <h6>Bill From</h6>
                        <p>
                            <strong>Seair Exim Solution's</strong>

                        </p>
                        <p>
                        Plot C2, Office No 2, First Floor, Mahindra park, Opp. GRID Office C1 Janakpuri, West Delhi 110059, India
                        </p>
                        <p>
                            +91-9990837766
                        </p>
                        <p>
                            info@seair.co.in
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-7 mt-4 mt-md-0">
                    
                        
                        {(this.state.CustomerDetails.length > 0) ? this.state.CustomerDetails.map((Customer, custindex) => {
                            return(
<div className="payment_block">
                        <h6>To Client</h6>
                                <p><strong>{Customer.Companyname}</strong></p>
                                <p>{Customer.FirstName} {Customer.LastName}</p>
                                <p>{Customer.Address}</p>
                                <p>{Customer.EmailID}</p>
                                <p>{Customer.MobileNo}</p>
                                </div>
                            )}):<div>Loading</div>
                        }
                       
                    
                </div>
            </div>
            <div className="sec_table">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left w-50">Service Product</th>
                            <th className="text-left">Details</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    Subscription Plan Name
                                </strong>
                            </td>
                            <td className="text-left">
                               {planname}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    Records
                                </strong>
                            </td>
                            <td className="text-left">
                                {item.Records}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    Validity of Plan
                                </strong>
                            </td>
                            <td className="text-left">
                                {Moment(item.EndDate).format("DD-MMMM-yyyy")}
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    Cost INR
                                </strong>
                            </td>
                            <td className="text-left">
                            <CurrencyFormat value={lblcostINR} displayType={'text'} thousandSeparator={true} prefix={'₹'} renderText={value => <div>{value}</div>} />
                                
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    Cost USD
                                </strong>
                            </td>
                            <td className="text-left">
                            <CurrencyFormat value={totalAmountUSD} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />
                                
                            </td>
                        </tr>
                        <tr>
                            <td className="text-left">
                                <strong>
                                    GST & 18% in INR
                                </strong>
                            </td>
                            <td className="text-left">
                            <CurrencyFormat value={lblservicestax} displayType={'text'} thousandSeparator={true} prefix={'₹'} renderText={value => <div>{value}</div>} />
                                
                            </td>
                        </tr>
                        <tr>
                            <th className="text-left">
                                Net Amount Payable in INR
                            </th>
                            <th className="text-left">
                            <CurrencyFormat value={lblnetamount} displayType={'text'} thousandSeparator={true} prefix={'₹'} renderText={value => <div>{value}</div>} />
                                
                            </th>
                        </tr>
                        <tr className="border-top border-white">
                            <th className="text-left">
                                Net Amount Payable in USD
                            </th>
                            <th className="text-left">

                            <CurrencyFormat value={lblnetamountUSD} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <div>{value}</div>} />

                                {/* {lblnetamountUSD} */}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row pay_dets">
                <div className="col-lg-12">
                    <div className="payment_block">
                        <p>
                            Certified that the particulars given above are true and correct and the amount indicated represents the price actually charged and that there is no flow of additional consideration directly or indirectly from the buyer.
                        </p>
                        <p>
                            <strong>Legal Undertaking</strong>
                            Seair Exim Solutions makes all efforts to provide an error free and Timely report, however the data is based on private partners sources and Seair Exim Solutions does not take any responsibility for its correctness,completeness, changes in the formats,also in any circumstance the provided report and analysis shall not admissible for legal /official proceeding.This transaction subject to the jurisdiction of Delhi courts,India Only.
                        </p>
                        <p>
                            I am hereby on behalf of my company giving an undertaking that we will not reproduce, sell, share, distributes, lease, rent,copy, revise,modify the reports . Seair Exim Solution shall be remains the sole owner of the information and our rights are limited to usage of the same for strictly internal business activities.
                        </p>
                        <p>
                            I have read above terms and condition and agree to the same.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        )}): <div>Loading...</div>
    }
    </div>
    </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  