import React, { useState ,useEffect} from "react";
//import styles from "./Register.module.css";
// import { useHistory } from "react-router-dom";
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';

import { useForm } from "react-hook-form";
import 'react-phone-number-input/style.css'
//import PhoneInput,{isValidPhoneNumber,formatPhoneNumberIntl,isPossiblePhoneNumber  } from 'react-phone-number-input'
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"

// import flags from 'react-phone-number-input/flags'
import { callApi,APIcustomerID } from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';


const Createtoolbox = () => 
{
  const { register, handleSubmit } = useForm();
  //, formState: { errors }, watch
  const [message, setMessage] = useState();


  const [items, setItems] = React.useState([]);



  useEffect(() => 
  {
    // if (!isAddMode) {
        // get user and set form fields
        async function getCharacters() {
            const requestData={customerid:APIcustomerID()}
            const usaProfileDetails=callApi("POST",requestData,Constant.USDATATOOLBOXCOUNTRYLIST);
            usaProfileDetails.then((responseData)=>{
                setItems(responseData.DataResponse.Data.map(({ DisplayName,Country }) => ({ label: DisplayName, value: Country })));
            // responseData.DataResponse.Data.forEach(team => 
            //   {
                
            //     setItems({label: team.DisplayName,value:team.Country});
                  
            // });
        
                });
          }
          getCharacters();
      
}, []);

  
  const onSubmit = (data, e) => {
    setMessage({data: "Your Tool Box submission is in progress...",type: "alert alert-warning",});
    
        const requestData={ToolBoxID:0,CustomerID:APIcustomerID(),FileFormat:''
        ,FromDate:data.FromDate
        ,ToDate:data.ToDate
        ,Year:"1"//data.Year
        ,Country:data.Country
        ,Buyer:data.Buyer
        ,Supplier:data.Supplier
        ,Product:data.Product
        ,Month:"1"//data.Month
        ,Ports:data.Ports
        ,CreatedDate:new Date()
        ,IsActive:true
        ,DMLFlag:'I'

    };//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.USDATATOOLBOXCREATE);
        usaData.then((responseData)=>{
          console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Tool Box has been created successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          
          !responseData.DataResponse.error && e.target.reset();
        });
  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

  return (
    <div className="app">
    <Header />
    <Navigation/> 
    <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-6">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>Create Tool Box</h5>
                </div>
                <div className="pro_form sec_table invoice_table col-md-12">
        {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="FirstName">Choose Country</label>
              <span className="mandatory">*</span>
              <select className="form-control form_control" { ...register('Country')
              }>
              {/* onChange={e => setValue(e.currentTarget.value)} */}
              {items.map(({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))}

              </select>
              
            </div>
            {/* <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Newpassword">Select Year Data</label>
              <span className="mandatory">*</span>
              <select className="form-control form_control" { ...register('Year')
              }>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>

              </select>
            </div>
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">Select Month</label>
              <span className="mandatory">*</span>
              <select className="form-control form_control" { ...register('Month')
              }>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>

              </select>
            </div>
             */}
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">Product</label>
              <span className="mandatory">*</span>
              <input type="text"  required="required" { ...register('Product')
              } className="form-control form_control" placeholder="Enter Product" />
              
            </div>
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">Buyer</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Buyer')
              } className="form-control form_control" placeholder="Enter Buyer" />
              
            </div>
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">Supplier</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Supplier')
              } className="form-control form_control" placeholder="Enter Supplier" />
              
            </div>
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">Ports</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Ports')
              } className="form-control form_control" placeholder="Enter Ports" />
              
            </div>

            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">From Date</label>
              <span className="mandatory">*</span>
              <input type="date" { ...register('FromDate')
              } className="form-control form_control" required="required" placeholder="Enter From Date" />
              
            </div>
            <div className="form-group form_group col-sm-6 col-6">
              <label htmlFor="Companyname">To Date</label>
              <span className="mandatory">*</span>
              <input type="date" { ...register('ToDate')
              } className="form-control form_control" required="required" placeholder="Enter To Date" />
              
            </div>

            
            <div className="form-group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Submit
              </button>

              </div>
            </div>
            </div>
          </form>
       
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
      </div>
    
  

  );
};

export default Createtoolbox;