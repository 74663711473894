import React, { useState } from "react";
//import styles from "./Register.module.css";
//import { useHistory } from "react-router-dom";
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';

import { useForm } from "react-hook-form";
import 'react-phone-number-input/style.css'
//import PhoneInput,{isValidPhoneNumber,formatPhoneNumberIntl,isPossiblePhoneNumber  } from 'react-phone-number-input'
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"

// import flags from 'react-phone-number-input/flags'
import { callApi ,APIcustomerID} from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';

const ChangePassword = () => 
{
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [message, setMessage] = useState();
  // const history = useHistory();
  const password = useState({});
  password.current = watch("Newpassword", "");
  //const state = {value:''}
  const onSubmit = (data, e) => {
    setMessage({data: "Your Password update is in progress...",type: "alert alert-warning",});
        const requestData={customerid:APIcustomerID(),OldPassword:data.oldpassword,NewPassword:data.Newpassword};//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.USDATACHANGEPASSWORD);
        usaData.then((responseData)=>{
          //console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Password has been updated successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
    
        });
  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

  return (
    <div className="app">
    <Header />
    <Navigation/> 
    <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-6">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>Change Password</h5>
                </div>
                <div className="pro_form sec_table invoice_table col-md-12">
        {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="FirstName">Old Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('oldpassword', { required:{value:true,message:"Please enter your Old Password"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:5,message:"Please enter min 5 characters"} })
              } className="form-control form_control" placeholder="Enter Old password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.oldpassword && (
                <span className=" mandatory">
                  {errors.oldpassword.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="Newpassword">New Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('Newpassword', { required:{value:true,message:"Please enter New Password"}
              
              ,maxLength:{value:10,message:"Please enter max 10 characters"},
              minLength:{value:5,message:"Please enter min 5 characters"} })
              } className="form-control form_control" placeholder="Enter New Password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Newpassword && (
                <span className=" mandatory">
                  {errors.Newpassword.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="Companyname">Confirm Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('ConfirmPassword', {
          validate: value =>
            value === password.current || "The passwords do not match"
        })
              } className="form-control form_control" placeholder="Enter Confirm Password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.ConfirmPassword && (
                <span className=" mandatory">
                  {errors.ConfirmPassword.message}
                </span>
              )}
            </div>
            
            <div className="form-group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Change Password
              </button>

              </div>
            </div>
            </div>
          </form>
       
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
      </div>
    
  

  );
};

export default ChangePassword;