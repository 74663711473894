export const APiURl=
//"http://localhost:8443/";
"https://nodeapp.seair.co.in/"
//"http://beta.seair.co.in/";
export const USDATASEARCH=APiURl+'api/searchdata/usadata';
export const USDATACOUNT=APiURl+'api/searchdata/usadatacount';
export const USDATASUPPLIERCOUNT=APiURl+'api/searchdata/usadatasupplierCount';
export const USDATABUYERCOUNT=APiURl+'api/searchdata/usadatabuyerCount';

export const USDATASUPPLIER=APiURl+'api/searchdata/usadatasupplier';
export const USDATABUYER=APiURl+'api/searchdata/usadatabuyer';
export const USDATAPORTS=APiURl+'api/searchdata/usadataport';
export const USDATAMONTHS=APiURl+'api/searchdata/usadatamonth';
export const USDATAHSCODE=APiURl+'api/searchdata/usadatahscode';
export const USDATACOUNTRY=APiURl+'api/searchdata/usadatacountry';

export const USDATADOWNLOADVVIEWSINGLE=APiURl+'api/searchdata/shipmentsdetails';
export const USDATADOWNLOADSINGLE=APiURl+'api/searchdata/downloadsingle';
export const USDATADOWNLOADALL=APiURl+'api/searchdata/downloadall';

export const USDATALOGIN=APiURl+'api/customers/CustomerLogin';
export const USDATAPLANLIST=APiURl+'api/customers/usaplanlist';
export const USDATACURRENTSUBS=APiURl+'api/customers/currentsubscription';
export const USDATASUBSSTATUS=APiURl+'api/customers/subscriptionstatus';
export const USDATACHECKDOWNLOADS=APiURl+'api/customers/checkdownloads';
export const USDATACHECKTODAYDOWNLOADS=APiURl+'api/customers/checkdownloadstodays';
export const USDATACREATEORDER=APiURl+'api/payment/createorder';
export const USDATACREATEORDERPAYMENT=APiURl+'api/payment/paymentupdate';

export const USDATAINVOICELIST=APiURl+'api/invoice/invoices';
export const USDATACREATESUBSCRIPTION=APiURl+'api/invoice/createsubscription';

export const USDATAINVOICEDETAILS=APiURl+'api/invoice/invoicedetails';
export const USDATAINVOICEPDF=APiURl+'api/createpdf/genrateinvoice';
export const USDATAINVOICEPDFAWS=APiURl+'api/createpdf/awsstore';
export const USDATAINVOICEPDFAWSDOWNLOAD=APiURl+'api/createpdf/downloadawsstore';

export const USDATACREATEWORKPLACE=APiURl+'api/workplace/createworkplace';

export const USDATAWORKPLACE=APiURl+'api/workplace/workplace';
export const USDATAPROFILEDETAILS=APiURl+'api/customers/customerdetails';
export const USDATAEMAIL=APiURl+'api/customers/SendSeairMail';
export const USDATAPROFILEUPDATE=APiURl+'api/customers/customerupdate';
export const USDATAPROFILESIGNUP=APiURl+'api/customers/customersignup';
export const USDATACHANGEPASSWORD=APiURl+'api/customers/changepassword';
export const USDATAFORGETPASSWORD=APiURl+'api/customers/forgotpassword';
export const USDATATOOLBOXLIST=APiURl+'api/toolbox/toolboxlist';
export const USDATATOOLBOXCOUNTRYLIST=APiURl+'api/toolbox/toolboxcountry';
export const USDATATOOLBOXCREATE=APiURl+'api/toolbox/createtoolbox';
export const USDATATOOLBOXDETAILS=APiURl+'api/toolbox/detailtoolbox';
export const USDATACREATETICKETS=APiURl+'api/tickets/createtickets';
export const USDATATICKETLIST=APiURl+'api/tickets/ticketlist';
export const USDATATICKETSDETAILS=APiURl+'api/tickets/ticketdetails';
export const USDATATICKETLOGS=APiURl+'api/tickets/ticketlog';
export const GoogleTokenVerify=APiURl+'api/googletoken/tokenVerify';




// site key 6LdqJboiAAAAAE7ymM5CVjEMvBxveRoYbhhI4L1m
// secret key 6Le2UboiAAAAANWJGMtV24Pi7Zt3LqSfAy--xMxp



