import React from "react";
//import React, { useEffect, useState } from "react";
import { useHistory ,Link} from "react-router-dom";
import logoutimg  from '../../Usefull/images/logout.svg'

const Logout = () => {
  const history = useHistory();

  const logoutfun = () => {
    /* eslint-disable */
    const toLogout = confirm("Are you sure to logout ?");
    /* eslint-enable */
    if (toLogout) {
      localStorage.clear();
      history.push("/login");
      window.location.href = "/";  
    }
  };


  return (
            
    <>
   
   <Link to="/" onClick={() => logoutfun()} className="user_namelog"><img src={logoutimg} alt="Logout" ></img><span>Logout</span></Link>
             
           
    </>
  );
};

export default Logout;