import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'
import Logo from '../../Usefull/images/logo.png'
import { Helmet } from 'react-helmet';
const ForgotPassword = () => 
{
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState();
  // const history = useHistory();

  const onSubmit = (data, e) => {
    setMessage({data: "Forgot Password is in progress...",type: "alert alert-warning",});
    
    const requestData={emailid:data.emailid}
    const usaData=callApi("POST",requestData,Constant.USDATAFORGETPASSWORD);
    usaData.then((responseData)=>{
      console.log(responseData.DataResponse.error);
      setMessage({
        data: responseData.DataResponse.error || "Your Password has been successfully sent to your registered email id",
        type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
      });

      if(responseData.DataResponse.error===null)
      {
      
        !responseData.DataResponse.error && e.target.reset();
      }
      
       //this.setState({isSubmitting: false});
      //this.setState({usadata:responseData.data})
    });
    
  };
  

  return (
    <div className="app">
    <Helmet>
    <title>Forgot Password Recovery At Seair Exim Solution's</title>
    <meta name="description" content="Seair Exim Solutions offers various online USA data plans as USA export Import data, US import data, and US data of 80+ global countries with customs shipment details of importers in USA and exporters at affordable prices." />
            </Helmet>
    <section className="login_page">
    <div className="container-fluid">
    <div className="row">
    

      <div className="col-lg-4 col-sm-12 offset-lg-4 offset-md-0">
        <div className="login_height">
         <div className="login_sec">
         <Link to="/" className="logo">
         
           <img src={Logo} alt="Seair Exim Solution" />
         </Link>
            <h2 className="common_heading">Welcome Back</h2>
         <p className="common_tagline">
            Please Forget Password .
             </p>
         <div className="login_box">
        {message && (
          <div
            role="alert" className={message.type}
          >
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="inputForEmail">Email Address</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('emailid', { required:{value:true,message:"Please enter your email address"}
              ,pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please enter valid email id.',
              },
            maxLength:{value:150,message:"Please enter max 150 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter email address" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.emailid && (
                <span className=" mandatory">
                  {errors.emailid.message}
                </span>
              )}
            </div>
           
            <div className="form-group form_group col-sm-12 col-12">
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Forget Password
              </button>

              </div>
            </div>
          </form>
       
      </div>
      <p className="form_botm">New User ? <Link to="/account/register">Signup Now</Link></p>
      <p className="form_botm">  <Link to="/account/login">Login</Link></p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    </div>
  
  );
};

export default ForgotPassword;