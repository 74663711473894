import React, { useState } from "react";
//import styles from "./Register.module.css";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Logo from '../../Usefull/images/logo.png'
import { useForm  } from "react-hook-form";
import { Link } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput,{isValidPhoneNumber,formatPhoneNumberIntl,formatPhoneNumber,isPossiblePhoneNumber,parsePhoneNumber  } from 'react-phone-number-input'
// import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
// import flags from 'react-phone-number-input/flags'
import { callApi } from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';

const Register = () => 
{
  const { register, handleSubmit, formState: { errors }  } = useForm();
  const [message, setMessage] = useState();
  const [value, setValue] = useState();
  const [GoogleToken, setGoogleToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const history = useHistory();
  //const state = {value:''}
  const onSubmit = (data, e) => {
    const countryCallingCode = parsePhoneNumber(value).countryCallingCode;
    //console.log(countryCallingCode);
    //console.log(parsePhoneNumber(value).nationalNumber)
 const numverforlsq="+"+countryCallingCode+"-"+parsePhoneNumber(value).nationalNumber;
 console.log(numverforlsq);
if(value===undefined)
{
  setMessage({errors:{MobileNo:{value:true,message:"Please neter mobile number"}}});
  //console.log("Please enter mobile number")
}
else
{
  const RequestGoogleToken={"g-recaptcha-response":GoogleToken};
  const GoogleTokenVerifyData=callApi("POST",RequestGoogleToken,Constant.GoogleTokenVerify);
  GoogleTokenVerifyData.then((TokenResponse)=>{
    if(TokenResponse.response==="Successful")
    {
       setMessage({data: "Registration is in progress...",type: "alert alert-warning",});
      const requestData=data;//JSON.stringify(data)
      const usaData=callApi("POST",requestData,Constant.USDATAPROFILESIGNUP);
      usaData.then((responseData)=>{
    //    console.log(responseData.DataResponse.error);
        setMessage({
          data: responseData.DataResponse.error || "Registration in successfully...redirecting to dahsboard...",
          type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
        });
        if(responseData.DataResponse.error===null)
        {
          const leaddatafile=[{"Attribute":"FirstName","Value":data.FirstName},{"Attribute":"LastName","Value":data.FirstName},{"Attribute":"Company","Value":data.Companyname},{"Attribute":"EmailAddress","Value":data.EmailID},{"Attribute":"Phone","Value":numverforlsq},{"Attribute":"Source","Value":"SE-Registration"}]
          callApi("POST",leaddatafile,"https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r8777a99bf9a10ca67a7b40008683c371&secretKey=d6d17025c20694ede2ebd01627f9754f8ce51727");
          setTimeout(() => {
            localStorage.setItem("token", responseData.DataResponse.Data.token);
            history.push("/app/dashboard");
          }, 1000);
        }
        else
        {
          setRefreshReCaptcha(r => !r); 
        }
  
      });

    }
    else
    {
      setMessage({data: "Something went wrong, Please refresh the Page and try again",type: "alert alert-warning",});
      
    }
    

});
       


}
    

  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };
  
  return (
    <div className="app">
    <Helmet>
       <title>Registration Form Details - Seair Exim Solutions</title>
       <meta name="description" content="Registration Now with the world’s leading import export data portal of Seair Exim Solution and get details of buyers and suppliers all at one place." />
    </Helmet>
    <section className="login_page">
    <div className="container-fluid">
    <div className="row">
 

      <div className="col-lg-6 col-sm-12 offset-lg-3 offset-md-0">
        <div className="login_height">
         <div className="login_sec">
         <Link to="/" className="logo">
         
         <img src={Logo} alt="Seair Exim Solution" />
       </Link>
            <h2 className="common_heading">Welcome Back</h2>
         <p className="common_tagline">
            Please Signup to your Account.
             </p>
         <div className="login_box">
        {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        
          <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="FirstName">First Name</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('FirstName', { required:{value:true,message:"Please enter your First name"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter First Name" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.FirstName && (
                <span className=" mandatory">
                  {errors.FirstName.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="LastName">Last Name</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('LastName', { required:{value:true,message:"Please enter your Last name"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter Last Name" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.LastName && (
                <span className=" mandatory">
                  {errors.LastName.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="Companyname">Organization Name</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('Companyname', { required:{value:true,message:"Please enter organization name"}
              
            ,maxLength:{value:100,message:"Please enter max 100 characters"},
            minLength:{value:3,message:"Please enter min 3 characters"} })
              } className="form-control form_control" placeholder="Enter organization name" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Companyname && (
                <span className=" mandatory">
                  {errors.Companyname.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="EmailID">Email address</label>
              <span className="mandatory">*</span>
              <input type="text" { ...register('EmailID', { required:{value:true,message:"Please enter your email address"}
              ,pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Please enter valid email id.',
              },
            maxLength:{value:150,message:"Please enter max 150 characters"},
            minLength:{value:10,message:"Please enter min 10 characters"} })
              } className="form-control form_control" placeholder="Enter email address" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.EmailID && (
                <span className=" mandatory">
                  {errors.EmailID.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
            <label htmlFor="MobileNo">Mobile Number</label>
              <span className="mandatory">*</span>{value && formatPhoneNumberIntl(value)}
              <PhoneInput 
  placeholder="Enter Mobile Number"
  defaultCountry="IN"
  name="MobileNo"
   value={value}
  onChange={setValue}
   className="form-control form_control"
   required={(value? undefined : 'true')}
   
   ref={register('MobileNo',{value:value,
   //required:{value:(value? undefined : 'true'),message:"Please enter valid sfsfss Number"},
  //  pattern: {
  //   value: value ? undefined : 'required',
  //   message: 'Please enter valid email id.',
  // }
  })}
  
  
  />
  {/* National: {value && formatPhoneNumber(value)} */}
  {/* {(value? undefined : (<span  className= "mandatory"> {value ? (isPossiblePhoneNumber(value) ? undefined : 'Please enter valid mobile Number') : 'Please enter your Mobile Number' }</span>))} */}

<span  className= "mandatory"> {value ? (isPossiblePhoneNumber(value) ? undefined : 'Please enter valid mobile Number') : 'Please enter your Mobile Number' }</span>

            

              
              {
             
              errors.MobileNo && (
                
                <span className=" mandatory">
                  
                  {errors.MobileNo.message}
                </span>
              )}
            </div>
            <div className="form-group form_group col-sm-6 col-12">
              <label htmlFor="Password">Password</label>
              <span className="mandatory">*</span>
              <input type="password" {...register('Password', { required:{value:true, message: "Please enter password"},minLength:{value:5,message:"Please enter min 5 characters"},maxLength:{value:10,message:"Please enter max 10 characters"}  })} className="form-control form_control" placeholder="Enter password" />
              
              {errors.Password && (
                <span className= "mandatory">
                  {errors.Password.message}
                </span>
              )}
            </div>
            <div className="form-group col-sm-12 col-12">
          
            <div className=" form_btn" >
              <button type="submit" className="btn_default btn_default2 noradius">
                Signup
              </button>

              </div>
            </div>
            </div>
            <GoogleReCaptchaProvider reCaptchaKey="6Le2UboiAAAAAEIfuHWFgZrSrJ5PZVGfSuMoBsji">
    <GoogleReCaptcha onVerify={setGoogleToken}   refreshReCaptcha={refreshReCaptcha} />
  </GoogleReCaptchaProvider>
           
          </form>
       
      </div>
      <p className="form_botm">Already have an Account ?  <Link to="/account/login" >
         
      Login
       </Link></p>
      <p className="form_botm"> <Link to="/account/forget">Forgot Password</Link></p>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>
    
    </div>
  

  );
};

export default Register;