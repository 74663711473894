import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import { callApi,APIcustomerID } from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';


const Currentsubscriptions = () => 
{
  
  const [items, setItems] = React.useState([]);

 

  useEffect(() => 
  {
   
    // if (!isAddMode) {
        // get user and set form fields
        async function getCharacters() {
            const requestData={customerid:APIcustomerID()}
            const usaProfileDetails=callApi("POST",requestData,Constant.USDATACURRENTSUBS);
            usaProfileDetails.then((responseData)=>{

//                 responseData.DataResponse.forEach(team => 
//                     {
// setItems({Plans:team.Plans})
//                     });
                
                setItems(responseData.DataResponse.map(({ Plans}) => ({ lable: Plans })));
           
        
                });
          }
          getCharacters();
   

      
}, []);

  
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

  return (
    <div className="dash_tab top">
        <ul className="nav nav-tabs">
        { (items.length > 0) ? items.map( (item, index) => {
          let PlanName=item.lable;
          let PlanLink=<Link to="/app/invoices" className="nav-link">{item.lable}</Link>
          if(PlanName===null)
          {
            PlanLink=<Link to="/" className="nav-link">Buy Plan </Link>
          }
          if(PlanName==="NA")
          {
            PlanLink=<Link to="/" className="nav-link">Buy Plan </Link>
          }
          
             return (
                <li className="nav-item">
                  {PlanLink}
                  {/* <div className="year_dropdown">
                    <ul>
                           
                     <li ><a href="#" className="submit_datapopulate" data-value="2014">2014</a></li>
                            
                           
                    </ul>
                  </div> */}
        </li>
                )
                 }) :  <li className="nav-item">
                 <Link to="/" className="nav-link">
                 Buy Plan</Link></li> }
            
  </ul>
  </div>

  );
};

export default Currentsubscriptions;