import React from 'react';
//debugger;
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const Table2 = ({ supplierlist ,onChangevalueData,onclickmoresupplier,SupplierShowButton }) => {
  let MoreDiv=<div>No More Data</div>
  if(SupplierShowButton>0)
  {
    MoreDiv=<button className="btn btn-primary" onClick={(data)=>onclickmoresupplier(JSON.parse(supplierlist).length)} >Load More</button>
  }
  return (
          <div className="sec_block">
            <div className="sec_blockhead">
              <h5>Supplier List Quantity</h5>
            </div>
            <div className="sec_table srchrslt_table">
            <div  className="all_filter">
              <Table className="table">
                <Thead>
                  <Tr>
                    <Th>Select </Th>
                    <Th>Supplier Name</Th>
                    <Th>Total Quantity</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {  JSON.parse(supplierlist).map( (item, index) => {
                    return (
                    <Tr key={ index }>
                    <Td><label><input type="checkbox" onChange={(data)=>onChangevalueData(item.key)} /></label></Td>
                    <Td>{item.key}</Td>
                    <Td>{item.Total.value}</Td>
                    </Tr>
                    )
                     } )}
                     <Tr><Td colSpan="3">

                     {MoreDiv}
                                    
                      </Td></Tr>
                </Tbody>
              </Table>
           </div>
         </div>
     </div>

    
  );
}
export default Table2