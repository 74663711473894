import React, { Component } from 'react';
import { withRouter , Link } from "react-router-dom";
import {

  startOfDay,
  addYears
  //startOfYear
 
} from 'date-fns';
import { Helmet } from 'react-helmet';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faTrash,faBookOpen,faEye } from '@fortawesome/free-solid-svg-icons'
import { callApi,APIcustomerID } from '../../Utilities/Api'
import * as Constant from '../../Utilities/Constant';
import Moment from 'moment';
class Dashboard extends Component {
  
  constructor(props) 
  {
   
    
    super(props);
  
    var StartDate = addYears(new Date(),-1);
    var Enddate =startOfDay(new Date(),1);
  
    // console.log(StartDate)
    // console.log(Enddate)
    this.state = {
      productname:'',
      buyername:'',
      suppliername:'',
      Toolboxdata:[],
      subsdetails:[],
      isMessage: true,
      messagedata:'',
      startDate:StartDate,//Moment(StartDate).format("DD-MMM-yyyy",true),//new Date('01-01-2020'),
      endDate:Enddate//Moment(Enddate).format("DD-MMM-yyyy",true)//new Date('01-01-2021')
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlebuyername = this.handlebuyername.bind(this);
    this.handlesuppliername = this.handlesuppliername.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.toolboxview = this.toolboxview.bind(this);
  }
  
  handleChange(event) {
    this.setState({productname: event.target.value.toLowerCase()});
  }
  handlebuyername(event) {
    this.setState({buyername: event.target.value.toLowerCase()});
  }
  handlesuppliername(event) {
    this.setState({suppliername: event.target.value.toLowerCase()});
  }
  
  
  componentDidMount() {
    
    this.ToolboxList();
    this.SubsDetailsfunction();
 
  }
  async ToolboxList()
    {
        const requestData={CustomerID:APIcustomerID()}
        const usaInvoiceDetails=callApi("POST",requestData,Constant.USDATATOOLBOXLIST);
        usaInvoiceDetails.then((responseData)=>{
            //console.log(responseData);
        this.setState({Toolboxdata:responseData.DataResponse.Data});
          
            });
        
         
    }

     
    async SubsDetailsfunction()
    {
            const requestData={customerid:APIcustomerID()}
            const usaProfileDetails=callApi("POST",requestData,Constant.USDATACURRENTSUBS);
            usaProfileDetails.then((responseData)=>{
              this.setState({subsdetails:responseData.DataResponse});
              console.log(this.state.subsdetails)
             });
    }
handleDelete(data) 
    {

      const requestData={ToolBoxID:data,CustomerID:APIcustomerID(),FileFormat:''
     
      ,DMLFlag:'D'

  };//JSON.stringify(data)
      const usaData=callApi("POST",requestData,Constant.USDATATOOLBOXCREATE);
      usaData.then((responseData)=>{
        this.ToolboxList();
      
    })
  }
  handleSubmit(event) 
  {
    let checkvalidation=false;
    let MessagesData="Please select atlest one field";
    if(this.state.productname!=="")
      {
        checkvalidation=true;
      }
      else if(this.state.suppliername!=="")
      {
        checkvalidation=true;
      }
      else if(this.state.buyername!=="")
      {
        checkvalidation=true;
      }
      else
      {
        checkvalidation=false;
      }
     

    if(checkvalidation)
    {
      this.props.history.push(
        '/app/search',
        this.state
        
    )
    }
    else{
      alert(MessagesData);
    }

    //this.setState({isMessage:checkvalidation,messagedata:MessagesData})
    
    

    
     event.preventDefault();
  
   
  }
  // ToolboxClick(data) 
  //   {

     
  // }
  // toolboxview(event) {
  //   console.log(event);
  //   //   this.props.history.push(
  //   //     '/app/search',
  //   //     this.state
        
  //   // )
  // }
  render() 
  {
  return (
            
    <div className="App">
       <Helmet>
    <title>Seair Exim Solutions Dashboard</title>
    <meta name="description" content="Seair Exim Solutions offers various online USA data plans as USA Import data" />
    </Helmet>
      <Header />
       <Navigation/> 
       <section className="dash_sec">
        <div className="container-fluid">
            <div className="row">
            <div className="col-md-12 " id="search_box">
              <div className="sec_block">
                <div className="sec_blockhead">
                  <h5>Search Data</h5>
                </div>
                {this.state.isMessage ? (
                          ""
                        ) : (
                          <div
                          role="alert" className="alert alert-danger">
                          <p className="mb-0">
                          
                          { this.state.messagedata}  
                          
                          </p>
                          
                        </div>
                        )} 
          
        
                <form onSubmit={this.handleSubmit}>
                  <div className="search_box pb-4">

                    <div className="form-group form_group">

                      <input type="text" value={this.state.productname} placeholder="Product Name" className="form-control form_control" onChange={this.handleChange} />

                    </div>
                    <div className="form-group form_group">

                      <input type="text" value={this.state.buyername} placeholder="Buyer" className="form-control form_control" onChange={this.handlebuyername} />

                    </div >
                    <div className="form-group form_group">

                      <input type="text" value={this.state.suppliername} placeholder="Supplier" className="form-control form_control" onChange={this.handlesuppliername} />

                    </div>
                    <div className="form-group sub_btn">
                      <button disabled={this.state.isSubmitting} className="btn btn-primary" type="submit" >
                        {this.state.isSubmitting ? (
                          "Loading..."
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </form>

              </div>

            </div>
            
            
            {(this.state.subsdetails.length > 0) ? this.state.subsdetails.map((item, index) => {
                  let Available=item.SubsRecords-item.DownloadRecords
                  let PlanName=item.Plans;
          let PlanLink=<Link to="/app/invoices" className="nav-link">{item.Plans}</Link>
          if(PlanName===null)
          {
            PlanLink=<Link to="/" className="nav-link">Buy Plan </Link>
          }
          if(PlanName==="NA")
          {
            PlanLink=<Link to="/" className="nav-link">Buy Plan </Link>
          }
return  (
  
<div className="col-md-6 mt-4 ">
<div className="sec_block ">
    <div className="sec_blockhead">
        <h5>Subscription Status</h5>
        <Link to="/app/workplace" className="user_namelog">View Transaction</Link>
       
        
    </div>
    <div className="sec_table invoice_table" >
      <table className="table">
        <thead>
          <tr>
          <th>Subscribed</th>
          <th>Downloaded</th>
          <th>Available</th>
          </tr>
        </thead>
        <tbody>
<tr>
  <td data-label="Subscribed">{item.SubsRecords}</td>
  <td data-label="Downloaded">{item.DownloadRecords}</td>
  <td data-label="Available">{Available}</td>
</tr>
        </tbody>
      </table>

      <div className="sec_blockdet">
        <div className="col-md-6">
            <h4>{PlanLink}</h4>
            
        </div>
        
        <div className="col-md-6">
            {/* <a href="#" className="btn_default btn_default2 noradius">Add Credit</a> */}
            <p className="validity">Valiity : {Moment(item.EndDate).format("YYYY-MM-DD")}</p>
        </div>
    </div>

    </div>
</div>
</div>


)

        }):""}

            <div className="col-md-3 mt-4">
    <div className="sec_block">
        <div className="sec_blockhead text-center">
            <h5 className="w-100">
                Tool Box
            </h5>
        </div>
        <div className="sec_blockdet">
           
            <div className="m-auto">
              <Link to="/app/createtoolbox" className="add_toolbox">
              <FontAwesomeIcon icon={faPlus}  />
              </Link>
                
            </div>
        </div>

       
    </div>
</div>

{(this.state.Toolboxdata.length > 0) ? this.state.Toolboxdata.map((item, index) => {
  let iProductName,iBuyer,iSupplier;
  

   if (item.Product !== null && item.Product !== "")
   {
     
    iProductName= <li> <strong>Product :</strong> {item.Product}</li>;
   }
   if (item.Buyer !== null && item.Buyer !== "")
   {
    iBuyer=  <li> <strong>Buyer :</strong> {item.Buyer}</li>;
   }
   if (item.Supplier !== null && item.Supplier !== "")
   {
    iSupplier=  <li> <strong>Supplier :</strong> {item.Supplier}</li>
   }
  //  @if (item.FileFormat != null && item.FileFormat != "")
  //  {
  //      <li><strong>File Format :</strong> {item.FileFormat}</li>
  //  }
          return(
<div className="col-md-3 mt-4">
        <div className="sec_block">
            <div className="sec_blockhead">
                <h5>
                    {item.Country.split(',')[1]}
                </h5>
                <div className="toobox_opt ml-auto">

                <button 
  className="btn-danger delete_toolbox"
  onClick={() => {
    const confirmBox = window.confirm(
      "Are you sure you want to delete this Tool Box permanently?"
    )
    if (confirmBox === true) {
     this.handleDelete(item.ToolBoxID)
    }
  }}>
    <FontAwesomeIcon icon={faTrash}  />
</button>

<Link  className="btn-success view_toolbox"
                                        //  to={'/app/invoiceprint/'+item.CustomerSubID}
                                        to={
                                            {
                                            pathname: '/app/updatetoolbox',
                                            state: {id:item.ToolBoxID}
                                       }}
                                        
                                      >
                                            <FontAwesomeIcon icon={faEye} />
                                            </Link>

                    {/* <a href="javascript:void(0)" data-toolboxid={item.ToolBoxID} className="btn-danger delete_toolbox" onclick="return confirm('Are you sure you want to delete this Tool Box permanently?')"> <FontAwesomeIcon icon={faTrash}  /></a> */}
                    <Link  className="btn-success view_toolbox"
                                        //  to={'/app/invoiceprint/'+item.CustomerSubID}
                                        to={
                                            {
                                            pathname: '/app/search',
                                            state: {ToolBoxID:item.ToolBoxID,startDate:item.FromDate,endDate:item.ToDate,
                                              productname:item.Product,
                                              buyername:item.Buyer,
                                              suppliername:item.Supplier

                                            
                                            }
                                       }}
                                        
                                      >
                                            <FontAwesomeIcon icon={faBookOpen} />
                                            </Link>
                    {/* <button  onClick={this.toolboxview(item.ToolBoxID)} className="btn-primary">
                                            <FontAwesomeIcon icon={faBookOpen} />
                                         </button> */}
                   
                </div>

            </div>
            <div className="sec_blockdet">
                <ul className="toolbox_ul">
                   {iProductName}
                   {iBuyer}
                   {iSupplier}
                        <li>
                            <strong>Created On :</strong> {Moment(item.CreatedDate).format("YYYY-MM-DD")}
                        </li>
                </ul>
            </div>
        </div>
    </div>

          )
        }): <div></div>}

       </div>
</div>
</section>
       </div>
      
  )
}
}
export default withRouter(Dashboard);


