import React, { Component } from 'react';
import {Link
} from "react-router-dom";

import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPrint } from '@fortawesome/free-solid-svg-icons'

import Moment from 'moment';

export default class Tickets extends Component {

    constructor(props) {
      super(props);
      this.state = {TicketList: []
       
    };
     
    }
    
    componentDidMount() {
        this.TicketListfunction();
     
      }
  
    handleSubmit(event) {
     


      event.preventDefault();
      
     
    }
    
    async TicketListfunction()
    {
        const requestData={CustomerID:APIcustomerID()}
        const usaTicketsList=callApi("POST",requestData,Constant.USDATATICKETLIST);
        usaTicketsList.then((responseData)=>{
        this.setState({TicketList:responseData.DataResponse});
          
            });
        
         
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-12">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>My Tickets</h5>
                    <Link to="/app/ticketcreate" className="user_namelog pull-right">
                                        Add New Ticket
                                        </Link>
                    {/* <a href="/Ticket/NewTicket" class="user_namelog">Add New Ticket</a> */}
                </div>
                <div className="sec_table invoice_table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>TicketNo</th>
                                <th>Subject</th>
                             
                                <th>Date</th>
                             
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        {(this.state.TicketList.length > 0) ? this.state.TicketList.map((item, index) => {
                                        
                                       
                                          return (
                                    <tr>
                                      <td data-label="TicketNo"> {item.TicketNo}</td>
                                      <td data-label="Subject"> {item.Subject}</td>
                                        <td data-label="Date"> {Moment(item.Sysdate).format("DD-MMM-yyyy")}</td>
                                      
                                        <td data-label="Status">{item.Status}</td>
                                      
                                       
                                        <td data-label="View">
                                      
                                        <Link 
                                        //  to={'/app/invoiceprint/'+item.CustomerSubID}
                                        to={
                                            {
                                            pathname: '/app/ticketdetail',
                                            state: {TicketNo:item.TicketNo}
                                       }}
                                        
                                      >
                                            <FontAwesomeIcon icon={faEye} />
                                            </Link>
                                            
                                           </td>
                                    </tr>
                        )}): <div></div>
                                    }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  