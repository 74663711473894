import React, { Component } from 'react';
import {Link
} from "react-router-dom";

import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi ,APIcustomerID} from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';

import Moment from 'moment';

export default class Invoices extends Component {

    constructor(props) {
      super(props);
      this.state = {InvoiceList: []
       
    };
     
    }
    
    componentDidMount() {
        this.Invoicelistfunction();
     
      }
  
    handleSubmit(event) {
     


      event.preventDefault();
      
     
    }
    
    async Invoicelistfunction()
    {
        const requestData={CustomerID:APIcustomerID()}
        const usaInvoiceList=callApi("POST",requestData,Constant.USDATAINVOICELIST);
        usaInvoiceList.then((responseData)=>{
        this.setState({InvoiceList:responseData.recordsets[0]});
          console.log(this.state.InvoiceList);
            });
        
         
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
        <div className="col-md-12">
            <div className="sec_block">
                <div className="sec_blockhead">
                    <h5>My Invoices</h5>
                </div>
                <div className="sec_table invoice_table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Record</th>
                                <th>Cost</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        {(this.state.InvoiceList.length > 0) ? this.state.InvoiceList.map((item, index) => {
                                        let BusinessPaln;
                                        if (item.SubscriptionID === 1) 
                                        {
                                            BusinessPaln = <td data-label="Promotional" className="promotional">Promotional</td>  ;
                                        } 
                                        else if (item.SubscriptionID === 2)
                                        {
                                            BusinessPaln = <td data-label="Standard">Standard</td>;
                                        } 
                                        else if (item.SubscriptionID === 3)
                                        {
                                            BusinessPaln =  <td data-label="Executive" className="executive">Executive</td>;
                                        } 
                                        else if (item.SubscriptionID === 4)
                                        {
                                            BusinessPaln = <td data-label="Business" className="business">Business</td>;
                                        } 
                                        else if (item.SubscriptionID === 5)
                                        {
                                            BusinessPaln = <td data-label="Corporate" className="corporate">Corporate</td>;
                                        } 
                                        let Status;
                                        if(item.IsPaid===true)
                                        {
                                            Status=<td data-label="Status" className="paid">Paid <i className="fas fa-check"></i></td>;
                                        }
                                        else
                                        {
                                            Status= <td data-label="Status" className="unpaid">UnPaid <i className="fas fa-times"> </i></td>;
                                        }
                                          return (
                                    <tr>
                                        {BusinessPaln}

                                       
                                        <td data-label="Start Date"> {Moment(item.StartDate).format("DD-MMM-yyyy")}</td>
                                        <td data-label="End Date">{Moment(item.EndDate).format("DD-MMM-yyyy")}</td>
                                        <td data-label="Record">{item.Records}</td>
                                        <td data-label="Cost">{item.Cost}</td>
                                        {Status}
                                        <td data-label="Print">
                                        <Link 
                                        //  to={'/app/invoiceprint/'+item.CustomerSubID}
                                        to={
                                            {
                                            pathname: '/app/invoiceprint',
                                            state: {id:item.CustomerSubID}
                                       }}
                                        
                                      >
                                            <FontAwesomeIcon icon={faPrint} />
                                            </Link>

                                           </td>
                                    </tr>
                        )}): <div></div>
                                    }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  