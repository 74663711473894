import React, { Component } from 'react';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import { callApi,APIcustomerID } from '../../Utilities/Api'

import * as Constant from '../../Utilities/Constant';
import Moment from 'moment';
export default class TicketDetails extends Component {

    constructor(props) {
      super(props);
      
        this.state = {
            TicketDetail: [],
            TicketLog: [],
            TicketNo:this.props.location.state.TicketNo
//            this.props.location.state.state.id
           // fromIdeas: props.params || 'unknown'
          
    };
     
    }
    
    componentDidMount() {
       
        this.Ticketsfunction();
     
      }
  
    async Ticketsfunction()
    {
        const requestData={TicketNo:this.state.TicketNo}
        
        const usaTicketDetails=callApi("POST",requestData,Constant.USDATATICKETSDETAILS);
        usaTicketDetails.then((responseData)=>{
        this.setState({TicketDetail:responseData.DataResponse});
        console.log(responseData.DataResponse.TicketID);
        const requestDataLog={TicketID:responseData.DataResponse[0].TicketID}
        const usaTicketLogs=callApi("POST",requestDataLog,Constant.USDATATICKETLOGS);
        usaTicketLogs.then((responseDatad)=>{
        this.setState({TicketLog:responseDatad.DataResponse});
            });

            });
        
         
    }
  
  
    render() 
    {
    //   this.searchDataBuyer();
      return (
        <div className="App">
        <Header />
        <Navigation/> 
        <section className="dash_sec">
            <div className="container-fluid">
            <div className="row">
            <div class="col-lg-6">
                <div class="sec_block">
            <div class="sec_blockhead">
                <h5>Ticket Details</h5>
            </div>
            <div class="table_tab">
                <table class="table ">
                    
{this.state.TicketDetail.map((item, index) => {
    return(
    <thead>
<tr>
<th>Ticket No.</th>
<td>
{item.TicketNo}
</td>
</tr>
<tr>
<th>Subject</th>
<td>
{item.Subject}
</td>
</tr>
<tr>
<th>Priority</th>
<td>
{item.Priority}
</td>
</tr>
<tr>
<th>Message</th>
<td>
{item.Message}
</td>
</tr>
<tr>
<th>Ticket Status</th>
<td>
{item.Status}
</td>
</tr>
<tr>
<th class="">Created Date</th>
<td>
{Moment(item.Sysdate).format("DD-MMM-yyyy")}
        
    
</td>
</tr>
</thead>)
})}

                       
                  
                </table>
            </div>
        </div>
            </div>

            <div class="col-md-12">
        <div class="sec_block">
            <div class="sec_blockhead">
                <h5>Logs</h5>
            </div>
            <div class="sec_table">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Ticket No.</th>
                            <th>Remarks</th>
                            <th>Ticket Status</th>
                            <th>Date Submitted</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.TicketLog.map((item, index) => {
    return(
                        
                            <tr>
                                <td data-lable="Ticket No.">{this.state.TicketNo}</td>
                                <td data-lable="Remarks">{item.Remarks}</td>
                                <td data-lable="Ticket Status">{item.Status}</td>
                                <td data-lable="Date Submitted">
                                {Moment(item.Sysdate).format("DD-MMM-yyyy")}
                                    
                                </td>
                            </tr>
    )})}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
            </div>
            </div>
        </section>
        </div>
        );
    }
    
  }
  