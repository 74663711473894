//import React, { Component } from 'react';
//import logo from './logo.svg';
import React, { Component,Link } from 'react';
//import  {useHistory}  from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { DateRangePicker,createStaticRanges  } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
  addDays,
  //subDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  subMonths
} from 'date-fns';
//import Table from './components/Table.js';
import Header from '../Shared/Header';
import Navigation from '../Shared/Navigation';
import UsaTable from './usaTable';
import SupplierTable from './summerySupplierTable';
import SupplierTableqty from './summerySupplierTableqty';
import BuyerTable from './summerybuyerTable';
import BuyerTableqty from './summerybuyerqty';
import CountryTable from './summeryCountryTable';
import PortsTable from './summeryPortsTable';
import MonthTable from './summeryMonthTable';
import HscodeTable from './summeryHscodeTable';
//import MonthlyGraph from './MonthlyGraph';
//import AppChart from './App_Graph';
import GoogleMonthly from './GoogleMonthly';
import GoogleHsCode from './GoogleHsCode';
import GoogleCountry from './GoogleCountry';
import GoogleBuyerShipments from './GoogleBuyerShipments';
import GoogleBuyerQuantity from './GoogleBuyerQuantity';
import GoogleSupplierShipments from './GoogleSupplierShipments';
import GoogleSupplierQuantity from './GoogleSupplierQuantity';
import {Tabs, Tab} from 'react-bootstrap-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint,faMinusSquare } from '@fortawesome/free-solid-svg-icons'
// import HscodeG from './Hscode';

import { callApi,APIcustomerID } from '../../Utilities/Api'
// const Constant.APiURl="http://localhost:3000/";
import * as Constant from '../../Utilities/Constant';
import Moment from 'moment';





export default class Data extends Component
{
  //debugger;
  constructor(props) {
   
    super(props);
    //this.props = props;
    
    // console.log(this.props.location.state.state.buyername)
    // console.log(this.props.location.state.state.productname)
    // console.log(this.props.location.state.state.suppliername)
  //console.log(this.props.state)

  //console.log(this.props.location.state.ToolBoxID);
    this.state = {
      //customers: [],
      TotalRecords: 0,
      Totalsupplier: 0,
      Totalbuyer: 0,
      usadata: [],
      productname:this.props.location.state.productname.toLowerCase(),
      buyername:this.props.location.state.buyername.toLowerCase(),
      suppliername:this.props.location.state.suppliername.toLowerCase(),
      suppliernamesearch: [],
      buyernamesearch: [],
      hscodenamesearch: [],
      countrynamesearch: [],
      portsnamesearch: [],
      monthnamesearch: [],
      supplierlist: [],
      buyerlist: [],
      afterbuyer:'',
      Totalcountry:'',
      TotalPorts:'',
      countrylist: [],
      portslist: [],
      perPage: 10,
      page: 0,
      pages: 0,
      monthsdata:[],
      hscodelist:[],
      hscode:'',
      isSubmitting: false,
      checkallsupplier: false,
      dataPoints: [],
      dataPointsGoogle: [],
      dataPointsGoogleHscode: [],
      dataPointsGoogleCountry: [],
      dataPointsGoogleBuyerShipments: [],
      dataPointsGoogleBuyerQuantity: [],
      dataPointsGoogleSupplierShipments: [],
      dataPointsGoogleSupplierQuantity: [],
      dataDownloadsSingle:[],
      supplierPerPage: 10,
      buyerPerPage: 10,
      SupplierShowButton:0,
      BuyerShowMoreButton:0,
      isMessage:true,
      OpenCalender:false,
     
    // //  selection: {
    //    startDate: this.props.location.state.startDate,
    //    endDate: this.props.location.state.endDate,
    // //   }
      selection: {
      startDate: this.props.location.state.startDate,
      endDate: this.props.location.state.endDate,
      }



      
      
    };
    // setState({
    //   buyername:this.props.location.state.state.buyername,
    //   productname:this.props.location.state.state.productname,
    //   suppliername:this.props.location.state.state.suppliername
       
    // })
    this.handleChange = this.handleChange.bind(this);
    this.handlebuyername = this.handlebuyername.bind(this);
    this.handlesuppliername = this.handlesuppliername.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSingleDownload = this.handleSingleDownload.bind(this);
    this.handleSingleDownloadAll = this.handleSingleDownloadAll.bind(this);
    this.handlecalender = this.handlecalender.bind(this);
    
  }
  
  handleChange(event) {
    this.setState({productname: event.target.value.toLowerCase()});
  }
  handlebuyername(event) {
    this.setState({buyername: event.target.value.toLowerCase()});
  }
  handlesuppliername(event) {
    this.setState({suppliername: event.target.value.toLowerCase()});
  }


  handleSubmit(event) {

    this.setState({isSubmitting: true});
    let checkvalidation=false;
    let MessagesData="Please select atlest one field";
    if(this.state.productname!=="")
      {
        checkvalidation=true;
      }
      else if(this.state.suppliername!=="")
      {
        checkvalidation=true;
      }
      else if(this.state.buyername!=="")
      {
        checkvalidation=true;
      }
      else
      {
        checkvalidation=false;
      }
     

    if(checkvalidation)
    {
      this.searchDataMethod();
    }
    this.setState({isMessage:checkvalidation,messagedata:MessagesData})
    
    this.setState({isSubmitting: false});
    event.preventDefault();
    
   
  }
  handleSingleDownload(event) 
  {


    event.preventDefault();

    
  let icustomerid=APIcustomerID();


const requestDataStatus={customerid:icustomerid}
const USAPlanStatus=callApi("POST",requestDataStatus,Constant.USDATASUBSSTATUS);
USAPlanStatus.then((responseData)=>{

if(responseData.DataResponse[0].Total>0)
{
  
  let TotlDonwloadrecords=this.state.dataDownloadsSingle.length;
  if(TotlDonwloadrecords>0)
  {
  const DownloadStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKDOWNLOADS);
  DownloadStatus.then((dresponseData)=>
  {
  
    var Totalremaining=dresponseData.DataResponse[0].Totalremaining;
    if(Totalremaining>TotlDonwloadrecords)
    {
      //console.log(dresponseData.DataResponse[0].Totalremaining);
      const DownloadTodayStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKTODAYDOWNLOADS);
      DownloadTodayStatus.then((DTodayresponseData)=>{

        Totalremaining= DTodayresponseData.DataResponse[0].Totalremaining;
        if(Totalremaining>TotlDonwloadrecords)
        {
              
                
                    var displayname="";
                    if (this.state.productname !== "") {
                      displayname = displayname + " " + this.state.productname;
                    }
                    if (this.state.buyername !== "") {
                      displayname = displayname + " " + this.state.buyername;
                    }
                    if (this.state.suppliername !== "") {
                      displayname = displayname + " " + this.state.suppliername;
                    }
                  const requestData={CustomerID:icustomerid,SourceIP:'',FileName:displayname.trim()+".xlsx",DisplayName:displayname,TotalRecords:TotlDonwloadrecords}
                  const usaData=callApi("POST",requestData,Constant.USDATACREATEWORKPLACE);
                  usaData.then((responseData)=>{

                    let FileName=responseData.DataResponse.Data[0].DownloadsID+'.xlsx'
                    //For Saving Data excel data
                    const requestDataExcel={CustomerID:icustomerid,SourceIP:'',FileName:FileName,datarecords:JSON.stringify(this.state.dataDownloadsSingle)}
                    const usaDataExcel=callApi("POST",requestDataExcel,Constant.USDATADOWNLOADSINGLE);
                    usaDataExcel.then((responseDataExcel)=>{
                      
                     alert("Your excel file is available on workplace.")
                      
                      //this.setState({isSubmitting: false});
                      
                    });
                    //End Excel Saving Dta
                    
                   // this.setState({isSubmitting: false});
                    
                  });

               
        }
        else
        {
          alert("Your daily data limit is exceeded.")
          
        }
       
      });
    }
    else
    {
      alert("Insufficient Data Access")
      
    }
    
  
  });
}
else
{
  alert("Please Select Records")
}

}
else
{
  alert("No Subscription please buy the plan.")
}


});



   
this.setState({isSubmitting: false});
    

    //event.preventDefault();
    
   
  }
  handleSingleDownloadAll(event) {


    let icustomerid=APIcustomerID();


    const requestDataStatus={customerid:icustomerid}
  const USAPlanStatus=callApi("POST",requestDataStatus,Constant.USDATASUBSSTATUS);
  USAPlanStatus.then((responseData)=>{
  
  if(responseData.DataResponse[0].Total>0)
  {
    console.log(responseData.DataResponse[0].Total);
    let TotlDonwloadrecords=this.state.TotalRecords;
    if(TotlDonwloadrecords>0)
    {
    const DownloadStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKDOWNLOADS);
    DownloadStatus.then((dresponseData)=>
    {
    
      var Totalremaining=dresponseData.DataResponse[0].Totalremaining;
      if(Totalremaining>TotlDonwloadrecords)
      {
        //console.log(dresponseData.DataResponse[0].Totalremaining);
        const DownloadTodayStatus=callApi("POST",requestDataStatus,Constant.USDATACHECKTODAYDOWNLOADS);
        DownloadTodayStatus.then((DTodayresponseData)=>{
  
          Totalremaining= DTodayresponseData.DataResponse[0].Totalremaining;
          if(Totalremaining>TotlDonwloadrecords)
          {
                
                  
                      var displayname="";
                      if (this.state.productname !== "") {
                        displayname = displayname + " " + this.state.productname;
                      }
                      if (this.state.buyername !== "") {
                        displayname = displayname + " " + this.state.buyername;
                      }
                      if (this.state.suppliername !== "") {
                        displayname = displayname + " " + this.state.suppliername;
                      }
                    const requestData={CustomerID:icustomerid,SourceIP:'',FileName:displayname.trim()+".xlsx",DisplayName:displayname,TotalRecords:TotlDonwloadrecords}
                    const usaData=callApi("POST",requestData,Constant.USDATACREATEWORKPLACE);
                    usaData.then((responseData)=>{
  
                      let FileName=responseData.DataResponse.Data[0].DownloadsID+'.xlsx'
                      //For Saving Data excel data
                      const requestDataExcel={CustomerID:APIcustomerID(),TotalRecords:this.state.TotalRecords,SourceIP:'',FileName:FileName,
                      productname:this.state.productname.trim(),buyername:this.state.buyername.trim(),suppliername:this.state.suppliername.trim(),
                     after:this.state.afterbuyer,size:this.state.perPage,from:this.state.page,supplierPerPage:this.state.supplierPerPage
                     ,buyerPerPage:this.state.buyerPerPage,
                     suppliernamesearch:JSON.stringify(this.state.suppliernamesearch),
                     buyernamesearch:JSON.stringify(this.state.buyernamesearch),
                     hscodenamesearch:JSON.stringify(this.state.hscodenamesearch),
                     countrynamesearch:JSON.stringify(this.state.countrynamesearch),
                     portsnamesearch:JSON.stringify(this.state.portsnamesearch),
                     monthnamesearch:JSON.stringify(this.state.monthnamesearch),
                     startDate:this.state.selection.startDate,
                      endDate:this.state.selection.endDate,
                      from:0
                     
                     }
                      const usaDataExcel=callApi("POST",requestDataExcel,Constant.USDATADOWNLOADALL);
                      usaDataExcel.then((responseDataExcel)=>{
                        
                       alert("Your excel file is available on workplace.")
                        
                        //this.setState({isSubmitting: false});
                        
                      });
                      //End Excel Saving Dta
                      
                     // this.setState({isSubmitting: false});
                      
                    });
  
                 
          }
          else
          {
            
            alert("Your daily data limit is exceeded.")
            
          }
         
        });
      }
      else
      {
        
        alert("Insufficient Data Access")
        
      }
      
    
    });
  }
  else{
    alert("Please Select Records")
  }
  
  }
  else
  {
    
    alert("No Subscription please buy the plan.")
  }
  
  
  });
  

    this.setState({isSubmitting: false});
   
 
   
  
 }
  startDownload(file, fileName) {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
  handlePageClick = (event) =>
   {
    

    let page = event.selected;
    this.setState({page});
    var Pagedata=page;
    if(page!==0)
      {
        Pagedata=((page*this.state.perPage)+1);
      } 


      

      this.setState({ page: Pagedata }, () => {
        const requestData=this.searchQueryBuild();
        this.searchData(requestData);
      })
    
    
    
   }

// search data

searchQueryBuild=()=>{
  const requestData={
    productname:this.state.productname.trim(),buyername:this.state.buyername.trim(),suppliername:this.state.suppliername.trim(),
    after:this.state.afterbuyer,size:this.state.perPage,from:this.state.page,supplierPerPage:this.state.supplierPerPage
    ,buyerPerPage:this.state.buyerPerPage,
    suppliernamesearch:JSON.stringify(this.state.suppliernamesearch),
    buyernamesearch:JSON.stringify(this.state.buyernamesearch),
    hscodenamesearch:JSON.stringify(this.state.hscodenamesearch),
    countrynamesearch:JSON.stringify(this.state.countrynamesearch),
    portsnamesearch:JSON.stringify(this.state.portsnamesearch),
    monthnamesearch:JSON.stringify(this.state.monthnamesearch),
    startDate:this.state.selection.startDate,
    endDate:this.state.selection.endDate


  }
    return requestData;
}

searchDataMethod=()=>{
    
  const requestData=this.searchQueryBuild();
   this.searchRecordsCount(requestData);
   this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
   
   this.searchData(requestData);
   this.searchDataSupplier(requestData);
   this.searchDataBuyer(requestData);
   this.searchDataCountry(requestData);
   this.searchDataPorts(requestData);
   this.searchDataMonth(requestData);
   this.searchDataHscode(requestData);

  
      
}
//start all Search Function
searchRecordsCount=(requestData)=>{
 
  const usaDataCount=callApi("POST",requestData,Constant.USDATACOUNT);
  usaDataCount.then((responseData)=>{
    this.setState({TotalRecords:responseData.dataCount})
  });
}
searchSupplierCount=(requestData)=>{
 
  const usaDataCount=callApi("POST",requestData,Constant.USDATASUPPLIERCOUNT);
  usaDataCount.then((responseData)=>{
   
   let TotalSupplier=responseData[0].TotalSupplier
    this.setState({Totalsupplier:TotalSupplier})
    var TotalPage=TotalSupplier;
    var page=this.state.supplierPerPage;
 
    if(TotalPage>page)
    {
  
      this.setState({SupplierShowButton:page})
    }
  
 

  });
}
searchBuyerCount=(requestData)=>{
 
  const usaDataCount=callApi("POST",requestData,Constant.USDATABUYERCOUNT);
  usaDataCount.then((responseData)=>{
    console.log(responseData)
   //this.setState({Totalbuyer:responseData[0].TotalBuyer})

   let TotalBuyer=responseData[0].TotalBuyer
    this.setState({Totalbuyer:TotalBuyer})
    var TotalPage=TotalBuyer;
    var page=this.state.buyerPerPage;
 
    if(TotalPage>page)
    {
  console.log("if");
      this.setState({BuyerShowMoreButton:page})
    }
   
  });
}


searchData=(requestData)=>
{

    const usaData=callApi("POST",requestData,Constant.USDATASEARCH);
    usaData.then((responseData)=>{
      //console.log(responseData.data);
      this.setState({usadata:responseData.data})
    });
}

searchDataSupplier=(requestData)=>
{

const usaDataSupplier=callApi("POST",requestData,Constant.USDATASUPPLIER);
usaDataSupplier.then((responseData)=>{
  //console.log(JSON.stringify(responseData.dataCount.suppliercount.buckets));
this.setState({supplierlist:JSON.stringify(responseData.dataCount.suppliercount.buckets)})
let teamsFromApiGoogle = responseData.dataCount.suppliercount.buckets.map(team => {
  return [team.key,team.doc_count]
});
this.setState({
  dataPointsGoogleSupplierShipments: [['Suppliername', 'Total']].concat(teamsFromApiGoogle)
});

let teamsFromApiGoogleQty = responseData.dataCount.suppliercount.buckets.map(team => {
  return [team.key,team.doc_count]
});
this.setState({
  dataPointsGoogleSupplierQuantity: [['Suppliername', 'Quantity']].concat(teamsFromApiGoogleQty)
});

});
}

searchDataBuyer=(requestData)=>
{

    const usaDataBuyer=callApi("POST",requestData,Constant.USDATABUYER);
    usaDataBuyer.then((responseData)=>{
    this.setState({buyerlist:JSON.stringify(responseData.dataCount.buyercount.buckets)})
    let teamsFromApiGoogle = responseData.dataCount.buyercount.buckets.map(team => {
      return [team.key,team.doc_count]
    });
    this.setState({
      dataPointsGoogleBuyerShipments: [['Buyername', 'Total']].concat(teamsFromApiGoogle)
    });
    let teamsFromApiGoogleQty = responseData.dataCount.buyercount.buckets.map(team => {
      return [team.key,team.doc_count]
    });
    this.setState({
      dataPointsGoogleBuyerQuantity: [['Buyername', 'Quantity']].concat(teamsFromApiGoogleQty)
    });
    
    

    });
}

searchDataCountry=(requestData)=>
{

const usaDataCountry=callApi("POST",requestData,Constant.USDATACOUNTRY);
    usaDataCountry.then((responseData)=>{
    this.setState({Totalcountry:responseData.dataCount.country.sum_other_doc_count+responseData.dataCount.country.buckets.length,countrylist:JSON.stringify(responseData.dataCount.country.buckets)})
    let teamsFromApiGoogle = responseData.dataCount.country.buckets.map(team => {
      return [team.key,team.doc_count]
    //return {x : team.key_as_string,y : team.doc_count}
    });
    this.setState({
      dataPointsGoogleCountry: [['Country', 'Total']].concat(teamsFromApiGoogle)
    });
    });
}

searchDataPorts=(requestData)=>
{

const usaDataPorts=callApi("POST",requestData,Constant.USDATAPORTS);
    usaDataPorts.then((responseData)=>{
    this.setState({TotalPorts:responseData.dataCount.ports.sum_other_doc_count+responseData.dataCount.ports.buckets.length,portslist:JSON.stringify(responseData.dataCount.ports.buckets)})
    });
}

searchDataMonth=(requestData)=>
{
const usaDataMonth=callApi("POST",requestData,Constant.USDATAMONTHS);
usaDataMonth.then((responseData)=>{
  this.setState({monthsdata:JSON.stringify(responseData.dataCount.Per_month.buckets)})

  // let teamsFromApi = responseData.dataCount.Per_month.buckets.map(team => {
  //   return {x : new Date(team.key_as_string),y : team.doc_count}
  // //return {x : team.key_as_string,y : team.doc_count}
  // });
  // this.setState({
  //   dataPoints: [{x: new Date("2018-03-01"), y: 85.3}].concat(teamsFromApi)
  // });


  let teamsFromApiGoogle = responseData.dataCount.Per_month.buckets.map(team => {
    return [Moment(team.key_as_string).format("MMM-yyyy"),team.doc_count]
  //return {x : team.key_as_string,y : team.doc_count}
  });
  this.setState({
    dataPointsGoogle: [['Year', 'Total']].concat(teamsFromApiGoogle)
  });


  
  //console.log(this.state.dataPointsGoogle);
  });
  
 }

searchDataHscode=(requestData)=>
{
//const requestData={productname:this.state.productname.trim(),buyername:this.state.buyername.trim(),suppliername:this.state.suppliername.trim(),suppliernamesearch:'',after:this.state.afterbuyer,size:10,from:0}
const usaDataHscode=callApi("POST",requestData,Constant.USDATAHSCODE);
  usaDataHscode.then((responseData)=>{
    this.setState({hscodelist:JSON.stringify(responseData.dataCount.hscode.buckets)})

    let teamsFromApiGoogle = responseData.dataCount.hscode.buckets.map(team => {
      return [team.key,team.doc_count]
    //return {x : team.key_as_string,y : team.doc_count}
    });
    this.setState({
      dataPointsGoogleHscode: [['HsCode', 'Sales']].concat(teamsFromApiGoogle)
    });

    });
}
//End start all Search Function

//Filter Action Start

supplierListMethod=(data)=>{


  if(this.state.suppliernamesearch.includes(data))
  {
    const items = this.state.suppliernamesearch.filter(item => item !== data);
    //this.setState({ suppliernamesearch: items });
    this.setState({ suppliernamesearch: items }, () => {
      this.CallFilterAll("S");
    })
    

  }
  else
  {
    
  this.state.suppliernamesearch.push(data);
  
  this.setState({suppliernamesearch:this.state.suppliernamesearch});
  this.CallFilterAll("S");
  }
  

}
buyerListMethod=(data)=>{

  

  if(this.state.buyernamesearch.includes(data))
  {
    const items = this.state.buyernamesearch.filter(item => item !== data);
    //this.setState({ buyernamesearch: items });
    this.setState({ buyernamesearch: items }, () => {
      this.CallFilterAll("B");
    })
    

  }
  else
  {
    
  this.state.buyernamesearch.push(data);
  this.setState({buyernamesearch:this.state.buyernamesearch});
  this.CallFilterAll("B");
  }


}
hscodeListMethod=(data)=>{

  

  if(this.state.hscodenamesearch.includes(data))
  {
    const items = this.state.hscodenamesearch.filter(item => item !== data);
    //this.setState({ hscodenamesearch: items });
    this.setState({ hscodenamesearch: items }, () => {
      this.CallFilterAll("HS");
    })
    
    
  }
  else
  {
    
  this.state.hscodenamesearch.push(data);
  this.setState({hscodenamesearch:this.state.hscodenamesearch});
  this.CallFilterAll("HS");
  }
  

}
countryListMethod=(data)=>{

  

  if(this.state.countrynamesearch.includes(data))
  {
    const items = this.state.countrynamesearch.filter(item => item !== data);
    //this.setState({ countrynamesearch: items });
    this.setState({ countrynamesearch: items }, () => {
      this.CallFilterAll("C");
    })
    

  }
  else
  {
    
  this.state.countrynamesearch.push(data);
  this.setState({countrynamesearch:this.state.countrynamesearch});
  this.CallFilterAll("C");
  }
  // const requestData=this.searchQueryBuild();
  //  this.searchRecordsCount(requestData);
  //  this.searchData(requestData);
  //  this.searchDataSupplier(requestData);
  //  this.searchDataBuyer(requestData);
  //  //this.searchDataCountry(requestData);
  //  this.searchDataPorts(requestData);
  //  this.searchDataMonth(requestData);
  //  this.searchDataHscode(requestData);

}
portsListMethod=(data)=>{

  

  if(this.state.portsnamesearch.includes(data))
  {
    const items = this.state.portsnamesearch.filter(item => item !== data);
    
//    this.setState({ portsnamesearch: items });
  this.setState({ portsnamesearch: items }, () => {
      this.CallFilterAll("P");
    })


  }
  else
  {

  this.state.portsnamesearch.push(data);
  this.setState({portsnamesearch:this.state.portsnamesearch});
  this.CallFilterAll("P");
 
  }

  

}

monthListMethod=(data)=>{

  console.log(data);

  if(this.state.monthnamesearch.includes(data))
  {
    const items = this.state.monthnamesearch.filter(item => item !== data);
    this.setState({ monthnamesearch: items }, () => {
      this.CallFilterAll("M");
    })
    

  }
  else
  {
    
  this.state.monthnamesearch.push(data);
  this.setState({monthnamesearch:this.state.monthnamesearch});
  this.CallFilterAll("M");
  }
  

}
CallFilterAll=(data)=>{
  
  const requestData=this.searchQueryBuild();
  
  if(data==="P")
  {

    this.searchRecordsCount(requestData);
    this.searchData(requestData);
    this.searchDataSupplier(requestData);
    this.searchDataBuyer(requestData);
    this.searchDataCountry(requestData);
    //this.searchDataPorts(requestData);
    this.searchDataMonth(requestData);
    this.searchDataHscode(requestData);
  }
  if(data==="C")
  {

    this.searchRecordsCount(requestData);
    this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
    this.searchData(requestData);
    this.searchDataSupplier(requestData);
    this.searchDataBuyer(requestData);
    //this.searchDataCountry(requestData);
    this.searchDataPorts(requestData);
    this.searchDataMonth(requestData);
    this.searchDataHscode(requestData);
  }
  if(data==="HS")
  {

    this.searchRecordsCount(requestData);
    this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
    this.searchData(requestData);
    this.searchDataSupplier(requestData);
    this.searchDataBuyer(requestData);
    this.searchDataCountry(requestData);
    this.searchDataPorts(requestData);
    this.searchDataMonth(requestData);
    //this.searchDataHscode(requestData);
  }
  if(data==="B")
  {

    this.searchRecordsCount(requestData);
    this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
    this.searchData(requestData);
    this.searchDataSupplier(requestData);
    //this.searchDataBuyer(requestData);
    this.searchDataCountry(requestData);
    this.searchDataPorts(requestData);
    this.searchDataMonth(requestData);
    this.searchDataHscode(requestData);
  }
  if(data==="S")
  {

    this.searchRecordsCount(requestData);
    this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
    this.searchData(requestData);
    //this.searchDataSupplier(requestData);
    this.searchDataBuyer(requestData);
    this.searchDataCountry(requestData);
    this.searchDataPorts(requestData);
    this.searchDataMonth(requestData);
    this.searchDataHscode(requestData);
  }
  if(data==="M")
  {

    this.searchRecordsCount(requestData);
    this.searchSupplierCount(requestData);
   this.searchBuyerCount(requestData);
    this.searchData(requestData);
    this.searchDataSupplier(requestData);
    this.searchDataBuyer(requestData);
    this.searchDataCountry(requestData);
    this.searchDataPorts(requestData);
    //this.searchDataMonth(requestData);
    this.searchDataHscode(requestData);
  }

}
supplierListMethodAll=(data)=>{
  console.log(data);
  this.setState({checkallsupplier:data});
  this.searchDataMethod();
}
DataListCheckBoxMethod=(data)=>{

  

  if(this.state.dataDownloadsSingle.includes(data))
  {
    const items = this.state.dataDownloadsSingle.filter(item => item !== data);
    this.setState({  dataDownloadsSingle: items }, () => {
     
    }); 
    
    

  }
  else
  {
    
  this.state.dataDownloadsSingle.push(data);
  this.setState({dataDownloadsSingle:this.state.dataDownloadsSingle}, () => {
     
  }); 
  
  
  }

  
  
  console.log(this.state.dataDownloadsSingle);
  //this.setState({checkallsupplier:data});
 // this.searchDataMethod();
}
singleview=(data)=>{
  
  this.props.history.push({
    pathname:'/app/shipmentsdetails',
    state:{Recordsid:data}
  }
)

  
}



buyerMoreData=(data)=>
{

  let TotalPage=this.state.Totalbuyer;
  var page=this.state.buyerPerPage+data;
  
  if(TotalPage>page)
  {
    this.setState({ buyerPerPage: page }, () => {
      const requestData=this.searchQueryBuild();
      this.searchDataBuyer(requestData);
    }); 
   
   
  }
  
  

  
}

SupplierMoreData=(data)=>
{
  
  let TotalPage=this.state.Totalsupplier;
  var page=this.state.supplierPerPage+data;
  if(TotalPage>page)
    {
      this.setState({ supplierPerPage: page }, () => {
      const requestData=this.searchQueryBuild();
      this.searchDataSupplier(requestData);
    }); 
    } 

  
}


subscriptionstatus=(CustomerID)=>
{
  var Istrue='false';
  const requestData={customerid:CustomerID}
const usaDataSupplier=callApi("POST",requestData,Constant.USDATASUBSSTATUS);
usaDataSupplier.then((responseData)=>{

if(responseData.DataResponse[0].Total>0)
{
Istrue='true';
console.log(Istrue);
}


});
return Istrue;
}
checkdownloads=(CustomerID)=>
{
  var Totalremaining=0;
  const requestData={customerid:CustomerID}
const usaDataSupplier=callApi("POST",requestData,Constant.USDATACHECKDOWNLOADS);
usaDataSupplier.then((responseData)=>{

  Totalremaining=responseData.DataResponse[0].Totalremaining;
console.log(Totalremaining);

});
return   Totalremaining;
}
checkdownloadstodays=(CustomerID)=>
{
  var Totalremaining=0;
  const requestData={customerid:CustomerID}
const usaDataSupplier=callApi("POST",requestData,Constant.USDATACHECKTODAYDOWNLOADS);
usaDataSupplier.then((responseData)=>{

  Totalremaining=responseData.DataResponse[0].Totalremaining;
  console.log(Totalremaining);

});
return   Totalremaining;
  
}

handleSelect=(ranges)=> {

  // var months=subMonths(new Date(ranges.selection.startDate),new Date(ranges.selection.endDate))
  // console.log(months);
  this.setState({
    selection : {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    }
  }, () => {
    this.searchDataMethod();
  }); 
  
  this.setState({OpenCalender:false});

}

handlecalender(event){

  let Isopen=this.state.OpenCalender;
  if(Isopen)
  {
    this.setState({OpenCalender:false});
  }
  else{
    this.setState({OpenCalender:true});
  }
  
  //this.setState({selection:{startDate:ranges.selection.startDate,endDate:ranges.selection.endDate}})
 // this.setState({startDate:ranges.startDate,endDate:ranges.startDate})
  // {
  //   selection: {
  //     startDate: [native Date Object],
  //     endDate: [native Date Object],
  //   }
  // }
}

sideBarOptions = () => 
{
  
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
  startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
  startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOflastYear: startOfYear(addYears(new Date(), -1)),
  endOflastYear: endOfYear(addYears(new Date(), -1))
};


  const customDateObjects = [
      // {
      //     label: 'Lifetime',
      //     range: () => ({
      //         startDate: defineds.startOfToday,
      //         endDate: defineds.endOfToday
      //     })
      // },
      // {
      //     label: 'Today',
      //     range: () => ({
      //         startDate: defineds.startOfToday,
      //         endDate: defineds.endOfToday
      //     })
      // },
      // {
      //     label: 'Last 7 Days',
      //     range: () => ({
      //         startDate: defineds.startOfLastSevenDay,
      //         endDate: defineds.endOfToday
      //     })
      // },
     
      
      {
          label: 'This Month',
          range: () => ({
              startDate: defineds.startOfMonth,
              endDate: defineds.endOfMonth
          })
      },
      {
          label: 'Last Month',
          range: () => ({
              startDate: defineds.startOfLastMonth,
              endDate: defineds.endOfLastMonth
          })
      },
      {
        label: 'Last 90 Days',
        range: () => ({
            startDate: defineds.startOfLastNintyDay,
            endDate: defineds.endOfToday
        })
    },
      {
          label: 'This Year',
          range: () => ({
              startDate: defineds.startOfYear,
              endDate: defineds.endOfYear
          })
      },
      {
          label: 'Last Year',
          range: () => ({
              startDate: defineds.startOflastYear,
              endDate: defineds.endOflastYear
          })
      }
  ];

  return customDateObjects;
};
BuyerFilterRemove=(data)=>
{
  console.log("asadadadad");
  console.log(data);
  
  // let TotalPage=this.state.Totalsupplier;
  // var page=this.state.supplierPerPage+data;
  // if(TotalPage>page)
  //   {
  //     this.setState({ supplierPerPage: page }, () => {
  //     const requestData=this.searchQueryBuild();
  //     this.searchDataSupplier(requestData);
  //   }); 
  //   } 

  
}

//end filter action

componentDidMount() 
    {

        const requestData={customerid:APIcustomerID()}
        const usaProfileDetails=callApi("POST",requestData,Constant.USDATACURRENTSUBS);
        usaProfileDetails.then((responseData)=>{

          let checksubs=responseData.DataResponse[0].Plans
          if(checksubs===null)
          {
            
            this.props.history.push('/')
          }
          else if(checksubs==="NA")
          {
            this.props.history.push('/')
          }
          else{
            this.searchDataMethod();
          }

          
        });
   
     
        
     
    }
  render() 
  {
    const selectionRange = {
      startDate: this.state.selection.startDate,
      endDate: this.state.selection.endDate,
      key: 'selection',
      
    }
    // console.log(selectionRange);
    const sideBar =this.sideBarOptions();
    const staticRanges = [
      // ...defaultStaticRanges,
      
      ...createStaticRanges(sideBar)
  ];
    return (
<div className="App">
      <Header />
      <Navigation/> 

      <section className="dash_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-sm-12 " id="search_box">
              <div className="sec_block">
                <div className="sec_blockhead">
                  <h5>Search Data</h5>
                </div>
                {this.state.isMessage ? (
                          ""
                        ) : (
                          <div
                          role="alert" className="alert alert-danger">
                          <p className="mb-0">
                          
                          { this.state.messagedata}  
                          
                          </p>
                          
                        </div>
                        )} 
                <form onSubmit={this.handleSubmit}>
                  <div className="search_box pb-4">

                    <div className="form-group form_group">

                      <input type="text" value={this.state.productname} placeholder="Product Name" className="form-control form_control" onChange={this.handleChange} />

                    </div>
                    <div className="form-group form_group">

                      <input type="text" value={this.state.buyername} placeholder="Buyer" className="form-control form_control" onChange={this.handlebuyername} />

                    </div >
                    <div className="form-group form_group">

                      <input type="text" value={this.state.suppliername} placeholder="Supplier" className="form-control form_control" onChange={this.handlesuppliername} />

                    </div>
                    <div className="form-group sub_btn">
                      <button disabled={this.state.isSubmitting} className="btn btn-primary" type="submit" >
                        {this.state.isSubmitting ? (
                          "Loading..."
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </form>

              </div>

            </div>
            
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 ">
              <button  className="user_namelog pull-right" onClick={this.handlecalender} type="button" >{Moment(this.state.selection.startDate).format("DD-MMM-yyyy")} to {Moment(this.state.selection.endDate).format("DD-MMM-yyyy")}</button>
              </div>
            <div className="col-md-12 col-sm-12">
            {this.state.OpenCalender ? (
                        <div className="user_tabshow_calender" >
                        <DateRangePicker staticRanges={staticRanges}  direction="horizontal" showMonthAndYearPickers="true" ranges={[selectionRange]} onChange={this.handleSelect} className="wrapper" ><input type="text" className="form-control" /></DateRangePicker>
                        </div>
                        ) : (
                         <div>
                         
                         </div>
                        )}
             

            </div>

            <div className=" col-md-12 col-sm-12">
            {(this.state.suppliernamesearch.length >0)? (
                          <div className="filterbox">
                          
                            <ul >
                            <li className="bactive"> Supplier Name:-</li>
                              {this.state.suppliernamesearch.map(name => (
                               <li>
                              
                               <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.supplierListMethod(name)}
             />
            
            </label> <span>
            {name} 
            </span>
                             </li>
                              ))}
              
                            </ul>
                            </div>
                        ) : (
                          ""
                        )} 
             {(this.state.buyernamesearch.length >0)? (
                         <div className="filterbox">
                        
                         <ul>
                           <li className="bactive"> Buyer Name:-</li>
                           {this.state.buyernamesearch.map(name => (
                             <li>
                             
                             <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.buyerListMethod(name)}
             />
             
            </label>
            <span>
            {name} 
            </span>
                             
                              
                           </li>
                           ))}
           
                         </ul>
                         </div>
                        ) : (
                          ""
                        )} 
              {(this.state.hscodenamesearch.length >0)? (
                       <div className="filterbox">
                       
                       <ul>
                       <li className="bactive"> Hs Code:-</li>
                         {this.state.hscodenamesearch.map(name => (
                          <li>
                       
                          <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.hscodeListMethod(name)}
             />
             
            </label> <span>
            {name} 
            </span>
                        </li>
                         ))}
         
                       </ul>
                       </div>
                        ) : (
                          ""
                        )} 
              {(this.state.countrynamesearch.length >0)? (
                       <div className="filterbox">
                       
                       <ul>
                       <li className="bactive"> Country Name:-</li>
                         {this.state.countrynamesearch.map(name => (
                          <li>
                         
                          <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.countryListMethod(name)}
             />
             
            </label>
            <span>
            {name.split(',')[1]} 
            </span>
                        </li>
                         ))}
         
                       </ul>
                       </div>
                        ) : (
                          ""
                        )} 
              {(this.state.portsnamesearch.length >0)? (
                       <div className="filterbox">
                         
                       
                       <ul>
                       <li className="bactive"> Port Name:-</li>
                         {this.state.portsnamesearch.map(name => (
                          <li>
                         
                          <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.portsListMethod(name)}
             />
             
            </label> <span>
            {name.split(',')[1]} 
            </span>
                        </li>
                         ))}
         
                       </ul>
                       </div>
                        ) : (
                          ""
                        )} 
               {(this.state.monthnamesearch.length >0)? (
                       <div className="filterbox">
                       
                       <ul>
                       <li className="bactive"> Month Name:-</li>
                         {this.state.monthnamesearch.map(name => (
                           <li>
                             
                             <label><input type="checkbox" checked="true" value={name} className="filterremove"
             onChange={(data)=>this.monthListMethod(name)}
             />
             
            </label>
            <span>
            {name} 
            </span>
                           </li>
                         ))}
         
                       </ul>
                       </div>
                        ) : (
                          ""
                        )} 
              
              
              </div>
            
           
              <div className="col-md-12 col-sm-12 mt-4 mb-4 dash_tab srch_tab">
              
                <Tabs defaultActiveKey="Home" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="home" label={"Total Records (" + this.state.TotalRecords+")"} title="Home">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="sec_block">
                      <div className="sec_blockhead">
                            <h5>Data List</h5>
                            <div className="search_blockhead" id="search_download" >
                               
                                    <button disabled={this.state.isSubmitting} className="user_namelog" onClick={this.handleSingleDownload} type="button" >
                        {this.state.isSubmitting ? (
                          "Loading..."
                        ) : (
                          "Download"
                        )}
                      </button>
                                
                      <button disabled={this.state.isSubmitting} className="user_namelog" onClick={this.handleSingleDownloadAll} type="button" >
                        {this.state.isSubmitting ? (
                          "Loading..."
                        ) : (
                          "Download All"
                        )}
                      </button>
                               
                               
                            </div>
                        </div>
                        <div className="sec_table srchrslt_table">
                        
                          {/* <button type="button" onClick={this.handleSingleDownload}>Download</button> */}
                          <UsaTable usadata={this.state.usadata} onChangevalueDataChekbox={(value) => this.DataListCheckBoxMethod(value)} singleview= {(value) => this.singleview(value)}/>
                          
                            <ReactPaginate previousLabel={'prev'} nextLabel={'next'} pageCount={this.state.TotalRecords / 10} onPageChange={this.handlePageClick} containerClassName={'pagination '} activeClassName={'active'} />
                          
                          
                        </div>
                      </div>
                    </div>
                    </div>

                  </Tab>
                  <Tab eventKey="Suppliers" label={"Suppliers (" + this.state.Totalsupplier+")"} title="Suppliers">
                  <div className="row">
                  <div className="col-md-12">
                      <GoogleSupplierShipments SupplierShipments={this.state.dataPointsGoogleSupplierShipments} />
                    </div>
                    <div className="col-md-6">
                      <SupplierTable supplierlist={this.state.supplierlist} SupplierShowButton={this.state.SupplierShowButton} onChangevalueData={(value) => this.supplierListMethod(value)} onclickmoresupplier={(value) => this.SupplierMoreData(value)} onChangevalueDataAll={(value) => this.supplierListMethodAll(value)} checkallsupplier={this.checkallsupplier} />
                    </div>
                    <div className="col-md-6">
                      <SupplierTableqty supplierlist={this.state.supplierlist} SupplierShowButton={this.state.SupplierShowButton} onChangevalueData={(value) => this.supplierListMethod(value)} onclickmoresupplier={(value) => this.SupplierMoreData(value)} onChangevalueDataAll={(value) => this.supplierListMethodAll(value)} checkallsupplier={this.checkallsupplier} />
                    </div>
                    <div className="col-md-12">
                      <GoogleSupplierQuantity SupplierQuantity={this.state.dataPointsGoogleSupplierQuantity} />
                    </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Buyers" label={"Buyers (" + this.state.Totalbuyer+")"} title="Buyers" >
                  <div className="row">
                    <div className="col-md-12">
                      <GoogleBuyerShipments BuyerShipments={this.state.dataPointsGoogleBuyerShipments} />
                    </div>
                    <div className="col-md-6">
                      <BuyerTable buyerlist={this.state.buyerlist} BuyerShowMoreButton={this.state.BuyerShowMoreButton} onclickmorebuyer={(value) => this.buyerMoreData(value)} onChangevalueDataBuyer={(value) => this.buyerListMethod(value)} afterbuyer={this.state.afterbuyer} />
                    </div>
                    <div className="col-md-6">
                      <BuyerTableqty buyerlist={this.state.buyerlist} BuyerShowMoreButton={this.state.BuyerShowMoreButton} onclickmorebuyer={(value) => this.buyerMoreData(value)} onChangevalueDataBuyer={(value) => this.buyerListMethod(value)} afterbuyer={this.state.afterbuyer}  />
                    </div>
                    <div className="col-md-12">
                      <GoogleBuyerQuantity BuyerQuantity={this.state.dataPointsGoogleBuyerQuantity} />
                    </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Trends" label={"Trends "} title="Trends" >
                    <div className="row">
                    <div className="col-md-12">
                      <GoogleMonthly monthsdata={this.state.dataPointsGoogle} />
                    </div>
                    <div className="col-md-12">
                      <GoogleHsCode HSCode={this.state.dataPointsGoogleHscode} />
                    </div>
                    <div className="col-md-12">
                      <GoogleCountry Country={this.state.dataPointsGoogleCountry} />
                    </div>
                   
                    </div>
                  </Tab>
                  <Tab eventKey="Filter" label={"Filter "} title="Filter" >
                    <div className="row">
                    <div className="col-md-6">
                      <SupplierTable supplierlist={this.state.supplierlist} onChangevalueData={(value) => this.supplierListMethod(value)} SupplierShowButton={this.state.SupplierShowButton}  onclickmoresupplier={(value) => this.SupplierMoreData(value)} onChangevalueDataAll={(value) => this.supplierListMethodAll(value)} checkallsupplier={this.checkallsupplier} />
                    </div>
                    <div className="col-md-6">
                      <BuyerTable buyerlist={this.state.buyerlist} BuyerShowMoreButton={this.state.BuyerShowMoreButton} onclickmorebuyer={(value) => this.buyerMoreData(value)} onChangevalueDataBuyer={(value) => this.buyerListMethod(value)}  />
                    </div>
                    <div className="col-md-6">
                      <HscodeTable hscodelist={this.state.hscodelist} onChangevalueData={(value) => this.hscodeListMethod(value)} />
                    </div>
                    <div className="col-md-6">
                      <CountryTable countrylist={this.state.countrylist} onChangevalueData={(value) => this.countryListMethod(value)} />
                    </div>
                    <div className="col-md-6">
                      <PortsTable portslist={this.state.portslist} onChangevalueData={(value) => this.portsListMethod(value)} />
                    </div>
                    <div className="col-md-6">
                      <MonthTable monthsdata={this.state.monthsdata} onChangevalueData={(value) => this.monthListMethod(value)} />
                    </div>
                    </div>
                  </Tab>
                </Tabs>


              </div>
               

          </div>
        </div>
      </section>
      </div>
      );
  }
  
}
